import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState, ILocalizationSchema } from '../../../interfaces';
import * as action from './actions';
import * as selectors from './selectors';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsSelectorsService {

  constructor(private store: Store<IAppState>) { }

  public lang$: Observable<string> = this.store.pipe(select(selectors.appLang));
  public dir$: Observable<string> = this.store.pipe(select(selectors.appDir));
  public setLang = (lang: string) =>
    this.store.dispatch(action.setLang({ data: lang }));

  public mainMenuExpantionFlag$: Observable<boolean> = this.store.pipe(select(selectors.mainMenuExpantionFlag));
  public setMainMenuExpantionFlag = (flag?: boolean) =>
    this.store.dispatch(action.setMainMenuExpantionFlag({ data: flag }));

  public userAuthorizationFlag$: Observable<boolean> = this.store.pipe(select(selectors.userAuthorizationFlag));
  public setUserAuthorizationFlag = (flag?: boolean) =>
    this.store.dispatch(action.setUserAuthorizationFlag({ data: flag }));

  public mobileDeviceFlag$: Observable<boolean> = this.store.pipe(select(selectors.mobileDeviceFlag));
  public setMobileDevieFlag = (flag?: boolean) =>
    this.store.dispatch(action.setMobileDeviceFlag({ data: flag }));

  public pageLcoalizationSchema$: Observable<ILocalizationSchema> = this.store.pipe(select(selectors.appL10n));
  public setPageLcoalizationSchema = (schema: ILocalizationSchema) =>
    this.store.dispatch(action.setAppLocalization({ data: schema }));

  public showUserMenuFlag$: Observable<boolean> = this.store.pipe(select(selectors.userMenuFLag));
  public setShowUserMenuFlag = (flag: boolean) =>
    this.store.dispatch(action.setUserMenuFlag({ data: flag }));

  public showSubMenuFlag$: Observable<boolean> = this.store.pipe(select(selectors.subMenuFLag));
  public setShowSubMenuFlag = (flag: boolean) =>
    this.store.dispatch(action.setSubMenuFlag({ data: flag }));

  public prefferedCurrency$: Observable<string> = this.store.pipe(select(selectors.currency));
  public setPrefferedCurrency = (currency: string) =>
    this.store.dispatch(action.setPrefferedCurrecny({ data: currency }));
  }
