import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IMapped, IRaw } from '@besinor/core';
import { IAppState } from '../../../interfaces';
import * as action from './actions';
import * as selectors from './selectors';

@Injectable({
  providedIn: 'root'
})
export class NavSettingsSelectorsService {

  constructor(private store: Store<IAppState>) { }

  public langLinks$ = this.store.pipe(select(selectors.langLinks));
  public setLangLinks = (links: any[]) =>
    this.store.dispatch(action.setLangLinks({ data: links }));

  public socialLinks$ = this.store.pipe(select(selectors.socialLinks));
  public setSocialLinks = (links: any[]) =>
    this.store.dispatch(action.setSocialLinks({ data: links }));

  public mainMenuLinks$ = this.store.pipe(select(selectors.mainMenuLinks));
  public setMainMenuLinks = (links: IRaw.INavigationStreamItem[]) =>
    this.store.dispatch(action.setMainMenu({ data: links }));

  public subMenuLinks$ = this.store.pipe(select(selectors.subMenuLinks));
  public setSubMenuLinks = (links: IRaw.INavigationHubItem[]) =>
    this.store.dispatch(action.setSubMenu({ data: links }));

  public userMenuLinks$ = this.store.pipe(select(selectors.userMenuLinks));
  public setUserMenuLinks = (links: IMapped.INavigationHubItem[]) =>
    this.store.dispatch(action.setUserMenuLinks({ data: links }));

  public footerLinks$ = this.store.pipe(select(selectors.footerLinks));
  public setFooterLinks = (links: IMapped.IArticleShort[]) =>
    this.store.dispatch(action.setFooterLinks({ data: links }));
}
