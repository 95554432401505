import { Component, Input } from '@angular/core';
import { IRaw } from '@besinor/core';
import { Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-article-structured-data',
  templateUrl: './article-structured-data.component.html',
  styleUrls: ['./article-structured-data.component.scss']
})
export class ArticleStructuredDataComponent {
  @Input() article: IRaw.IArticle;
  public ASYNC_DATA = (data: IRaw.IArticle): Observable<IRaw.IArticle> => of(data)
    .pipe(
      filter(((article: IRaw.IArticle) => Boolean(article))),
    );
}
