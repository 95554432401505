import { IAppPageSettings } from '../../../interfaces';
import { DEFAULT_UI_LANG, DEFAULT_UI_DIR } from '../../constants';

export const appInitialState: IAppPageSettings = {
  isAuthorized: false,
  isNavMenuExpanded: true,
  isMobileDevice: false,
  isUserMenuShown: false,
  isSubMenuShown: true,
  lang: DEFAULT_UI_LANG,
  dir: DEFAULT_UI_DIR,
  langs: [],
  l10n: {},
  currency: 'EURO'
}
