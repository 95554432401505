import { Routes } from '@angular/router';
import { LayoutsWrapperComponent } from './layouts-wrapper.component';
import { LandingPageComponent  } from '../layouts/landing-page/landing-page.component';
import { HubPageComponent } from '../layouts/hub-page/hub-page.component';
import { StreamPageComponent } from '../layouts/stream-page/stream-page.component';
import { ArticlePageComponent } from '../layouts/article-page/article-page.component';
import { SignUpComponent } from '../layouts/sign-up/sign-up.component';
import { SignUpActivationComponent } from '../layouts/sign-up-activation/sign-up-activation.component';
import { SignInComponent } from '../layouts/sign-in/sign-in.component';
import { UserNewPasswordComponent } from '../layouts/user-new-password/user-new-password.component';
import { ProfileLandingComponent } from '../layouts/profile/profile-landing/profile-landing.component';
import { DEFAULT_UI_LANG } from '../common/constants';
import { ProfileExitComponent } from '../layouts/profile/profile-exit/profile-exit.component';
import { IsAuthorizedGuard } from '../common/guards/is-authorized.guard';
import { ProfileArticlesComponent } from '../layouts/profile/profile-articles/profile-articles.component';
import { UserPageComponent } from '../layouts/user-page/user-page.component';
import { AmountSelectorPageComponent } from '../layouts/article-page/components/amount-selector-page/amount-selector-page.component';
import { AmountPaymentConfirmationPageComponent } from '../layouts/article-page/components/amount-payment-confirmation-page/amount-payment-confirmation-page.component';
import { ArticlePreviewPageComponent } from '../layouts/article-preview-page/article-preview-page.component';
import { ArticlesReviewPageComponent } from '../layouts/articles-review-page/articles-review-page.component';

export const routes: Routes = [
  {
    path: ':lang',
    component: LayoutsWrapperComponent,
    children: [
      {
        path: 'landing',
        component: LandingPageComponent,
      },
      {
        path: 'streams/:streamId',
        component: StreamPageComponent
      },
      {
        path: 'hubs/:hubId',
        component: HubPageComponent
      },
      {
        path: 'preview/:articleId',
        component: ArticlePreviewPageComponent
      },
      {
        path: 'review/:articleId',
        component: ArticlesReviewPageComponent
      },
      {
        path: 'articles/:articleId',
        component: ArticlePageComponent,
        children: [
          {
            path: '',
            component: AmountSelectorPageComponent
          },
          {
            path: 'donation/confirmed/:reciepeId',
            component: AmountPaymentConfirmationPageComponent
          }
        ]
      },
      {
        path: 'user/:userId',
        component: UserPageComponent
      },
      {
        path: 'sign-up',
        component: SignUpComponent
      },
      {
        path: 'sign-up-activation',
        component: SignUpActivationComponent,
      },
      {
        path: 'sign-up-activation-auto/:key',
        component: SignUpActivationComponent,
      },
      {
        path: 'sign-in',
        component: SignInComponent
      },
      {
        path: 'create-new-password/:key',
        component: UserNewPasswordComponent
      },
      {
        path: 'profile',
        component: ProfileLandingComponent,
        canActivate: [ IsAuthorizedGuard ],
        canActivateChild: [ IsAuthorizedGuard ],
        children: [
          {
            path: 'exit',
            component: ProfileExitComponent
          },
          {
            path: 'articles',
            component: ProfileArticlesComponent
          },
          { path: '**', redirectTo: `articles` }
        ]
      },
      { path: '**', redirectTo: `landing` }
    ]
  },
  // Not found
  { path: '**', redirectTo: `/${DEFAULT_UI_LANG}/landing` }
];
