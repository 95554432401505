import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule }  from 'ngx-sharebuttons/icons';
import { PipesModule } from '../../common/pipes/pipes.module';
import { ServicesModule } from '../../common/services/services.module';
import { DirectionDirective } from './direction.directive';
import { CommonFormsModule } from './forms/forms.module';
import { LoadMoreResultsComponent } from './load-more-results/load-more-results.component';
import { ShareComponent } from './share/share.component';
import { ArticleStructuredDataComponent } from './article-structured-data/article-structured-data.component';

@NgModule({
  declarations: [
    DirectionDirective,
    LoadMoreResultsComponent,
    ShareComponent,
    ArticleStructuredDataComponent,
  ],
  imports: [
    CommonModule,
    ServicesModule,
    CommonFormsModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  exports: [
    DirectionDirective,
    CommonFormsModule,
    LoadMoreResultsComponent,
    ShareComponent,
    ArticleStructuredDataComponent,
  ]
})
export class CommonComponentsModule { }
