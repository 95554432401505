import { Component, OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from '../../../common/services/settings.service';
import { NavSettingsSelectorsService } from '../../../common/state/navigation/nav-settings-selectors.service';
import { AppSettingsSelectorsService } from '../../../common/state/app/app-settings-selectors.service';
import { SeoService } from '../../../common/services/seo.service';
import { TranslatePipe } from '../../../common/pipes/translate.pipe';
import { profileMenu } from './constants';

@Component({
  selector: 'app-profile-landing',
  templateUrl: './profile-landing.component.html',
  styleUrls: ['./profile-landing.component.scss']
})
export class ProfileLandingComponent implements OnInit, OnDestroy {

  constructor(
    private settings: SettingsService,
    private navigation: NavSettingsSelectorsService,
    private appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) { }

  ngOnInit(): void {
    const subMenu = profileMenu(this.settings.lang);
    this.navigation.setUserMenuLinks(subMenu);
    this.appSettings.setShowSubMenuFlag(false);
    this.appSettings.setShowUserMenuFlag(true);

    this.seo.setSeoMetaData(
      this.translate.transform('seoMyPageTitle'),
      this.translate.transform('seoMyPageDesciption')
    );

  }

  ngOnDestroy() {
    this.appSettings.setShowSubMenuFlag(true);
    this.appSettings.setShowUserMenuFlag(false);
  }
}
