import { Component, OnInit, OnDestroy } from '@angular/core';
import { IMapped } from '@besinor/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators'
import { IRaw } from '@besinor/core';
import { ArticlesService } from '../../../common/services/articles.service';
import { UsersService } from '../../../common/services/users.service';
import { AppSettingsSelectorsService } from '../../../common/state/app/app-settings-selectors.service';
import { formatUserData, filterMissingFields } from '../../../common/helpers/users';
import { SeoService } from '../../../common/services/seo.service';
import { TranslatePipe } from '../../../common/pipes/translate.pipe';

@Component({
  selector: 'app-profile-articles',
  templateUrl: './profile-articles.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './profile-articles.component.scss'
  ]
})
export class ProfileArticlesComponent implements OnInit, OnDestroy {

  public items: IRaw.IArticle[] = [];
  public userData: IMapped.IUserProfile;
  public lang: string;
  public isLoading: boolean = false;
  public haveMore: boolean = true;

  private subscriptions: Subscription[] = [];
  private page$: BehaviorSubject<number> = new BehaviorSubject(0);
  private currentPage: number = 0;

  constructor(
    private articles: ArticlesService,
    private users: UsersService,
    public appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) { }

  onNextPage() {
    this.currentPage = this.currentPage + 1;
    this.page$.next(this.currentPage);
  }

  trackListChangingEvents() {
    this.subscriptions
      .push(
        this.page$
          .pipe(
            tap(() => this.isLoading = true),
            switchMap(() => this.articles.getMyArticlesByPage(this.currentPage)),
            tap(({ data: newItems = [] }) => {
              this.isLoading = false;
              if (newItems.length === 0) {
                this.haveMore = false;
              } else {
                this.items = [
                  ...this.items,
                  ...newItems
                ];
              }
            })
          )
          .subscribe()
      );
  }

  ngOnInit() {
    this.seo.setSeoMetaData(
      this.translate.transform('seoMyProfileTitle'),
      this.translate.transform('seoMyProfileDesciption')
    );

    this.trackListChangingEvents();

    this.subscriptions.push(
      this.users.getMyData()
      .pipe(
        map(({ data: [user]}) => formatUserData(user)),
        tap(data => this.userData = filterMissingFields(data))
      )
      .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
