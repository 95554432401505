<div class="breadcrumbs">
  <div class="breadcrumbs-container">
    <div *ngFor="let item of items" class="breadcrumbs-links">
      <a
        [routerLink]="item.url"
        [routerLinkActive]="['active']"
        class="breadcrumbs-links-link"
      >{{ item.data.text | translate:lang }}</a>
    </div>
  </div>
</div>
