import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-links',
  templateUrl: './side-links.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './side-links.component.scss'
  ]
})
export class SideLinksComponent {
  @Input() lang: string;
  @Input() isLogedIn: boolean = false;
}
