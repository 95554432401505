import { createAction, props } from '@ngrx/store';
import { IMapped, IRaw } from '@besinor/core';

export const setSocialLinks = createAction(
  '[NAV] set social links',
  props<{ data: any[] }>()
);

export const setLangLinks = createAction(
  '[NAV] set lang switching links',
  props<{ data: any[] }>()
);

export const setMainMenu = createAction(
  '[NAV] set main menu',
  props<{ data: IRaw.INavigationStreamItem[] }>()
);

export const setSubMenu = createAction(
  '[NAV] set submenu',
  props<{ data: IRaw.INavigationHubItem[] }>()
);

export const setFooterLinks = createAction(
  '[NAV] set footer links',
  props<{ data: IMapped.IArticleShort[] }>()
);

export const setUserMenuLinks = createAction(
  '[NAV] set user menu links',
  props<{ data: IMapped.INavigationHubItem[] }>()
);

