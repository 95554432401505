import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';

@Directive({
  selector: '[appLangDirection]'
})
export class DirectionDirective implements OnInit, OnDestroy {

  private sub: Subscription;

  constructor(private el: ElementRef, private appSettings: AppSettingsSelectorsService) { }

  ngOnInit() {
    this.sub = this.appSettings
      .dir$
      .pipe(
        distinctUntilChanged(),
        tap(dir => this.el.nativeElement.setAttribute('dir', dir))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

}
