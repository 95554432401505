import { createReducer, on } from '@ngrx/store';
import { IAppPageSettings } from '../../../interfaces';
import { LANG_SETTINGS, DEFAULT_UI_LANG } from '../../constants';
import * as actions from './actions';
import { appInitialState } from './model';

const onLangChange = (state: IAppPageSettings, { data: lang }) => ({
  ...state,
  lang,
  dir: LANG_SETTINGS[lang] ? LANG_SETTINGS[lang].dir : LANG_SETTINGS[DEFAULT_UI_LANG].dir
});

const onMainMenuExpantionFlagChange = (state: IAppPageSettings, { data: isNavMenuExpanded }) => ({
  ...state,
  isNavMenuExpanded: Boolean(state.isMobileDevice) ?
    (typeof isNavMenuExpanded !== 'undefined' ? isNavMenuExpanded : !state.isNavMenuExpanded)
    : true
});

const onSetUserAuthorizationFlagChange = (state: IAppPageSettings, { data: isAuthorized }) => ({
  ...state,
  isAuthorized
});

const onSetMobileDeviceFlagChange = (state: IAppPageSettings, { data: isMobileDevice }) => ({
  ...state,
  isMobileDevice,
  isNavMenuExpanded: isMobileDevice ? false : true
});

const onAppLocalizationChange = (state: IAppPageSettings, { data: l10n }) => ({
  ...state,
  l10n
})

const onSetUserMenuFlag = (state: IAppPageSettings, { data: isUserMenuShown }) => ({
  ...state,
  isUserMenuShown
});

const onSetSubMenuFlag = (state: IAppPageSettings, { data: isSubMenuShown }) => ({
  ...state,
  isSubMenuShown
});

export const appReducer = createReducer(appInitialState,
  on(actions.setLang, onLangChange),
  on(actions.setMainMenuExpantionFlag, onMainMenuExpantionFlagChange),
  on(actions.setUserAuthorizationFlag, onSetUserAuthorizationFlagChange),
  on(actions.setMobileDeviceFlag, onSetMobileDeviceFlagChange),
  on(actions.setAppLocalization, onAppLocalizationChange),
  on(actions.setSubMenuFlag, onSetSubMenuFlag),
  on(actions.setUserMenuFlag, onSetUserMenuFlag)
);
