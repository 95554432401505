import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IRaw } from '@besinor/core';

@Component({
  selector: 'besinor-article-cite',
  templateUrl: './cite.component.html',
  styleUrls: ['./cite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CiteComponent {
  @Input() data: IRaw.IArticleCite;
}
