import { Component, OnInit } from '@angular/core';
import { Subscription, of, combineLatest } from 'rxjs';
import { tap, map, switchMap, filter } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PAYMENT_HOST } from '../../../../constants';
import { AppSettingsSelectorsService } from '../../../../common/state/app/app-settings-selectors.service';
import { ArticlesService } from '../../../../common/services/articles.service';
import { InvoicesService } from '../../../../common/services/invoices.service';
import { donationItems } from './constants';

@Component({
  selector: 'app-amount-selector-page',
  templateUrl: './amount-selector-page.component.html',
  styleUrls: ['./amount-selector-page.component.scss']
})
export class AmountSelectorPageComponent implements OnInit {

  public isCreatingInvoice: boolean = false;
  private subscriptions: Subscription[] = [];
  public lang: string;
  private isSignedIn: boolean = false;
  public articleId: string;
  public currency: string;
  public donationItems = donationItems;

  constructor(
    private route: ActivatedRoute,
    private articlesService: ArticlesService,
    public settings: AppSettingsSelectorsService,
    private invoices: InvoicesService
  ) { }

  handleAmountSelected(amount) {
    this.isCreatingInvoice = true;

    this.subscriptions.push(
      of(amount)
        .pipe(
          filter(() => Boolean(this.articleId)),
          switchMap(() =>
            this.isSignedIn ?
              this.invoices.createInvoice(this.articleId, amount, this.currency) :
              this.invoices.createInvoiceAnanymously(this.articleId, amount, this.currency)
          ),
          tap(({ data: invoiceId }) => {
            const paymentUrl = `${PAYMENT_HOST}/${this.lang}/invoice/${invoiceId}/redirect/stripe`;
            window.location.href = paymentUrl;
          })
        )
        .subscribe()
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap
        .pipe(
          map((params: ParamMap) => params.get('articleId')),
          tap((articleId) => this.articleId = articleId)
        )
        .subscribe()
    );

    this.subscriptions.push(
      combineLatest([
        this.settings.lang$,
        this.settings.userAuthorizationFlag$,
        this.settings.prefferedCurrency$
      ])
        .pipe(
          tap(([lang, isSignedIn, currency]) => {
            this.lang = lang;
            this.isSignedIn = isSignedIn;
            this.currency = currency;
          })
        )
        .subscribe()
    )
  }

}
