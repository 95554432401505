import { Component, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IRaw } from '@besinor/core';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { ArticlesService } from '../../common/services/articles.service';
import { getArticleHeader } from '../../common/helpers/articles';

@Component({
  selector: 'app-hub-page',
  templateUrl: './hub-page.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './hub-page.component.scss'
  ]
})
export class HubPageComponent implements OnInit {

  public items: IRaw.IArticle[] = [];
  public topItems: IRaw.IArticle[] = [];
  public lang: string;
  public isLoading: boolean = false;
  public haveMore: boolean = true;

  private subscriptions: Subscription[] = [];
  private page$: BehaviorSubject<number> = new BehaviorSubject(0);
  private currentPage: number = 0;
  private hubId: string;

  constructor(
    private articles: ArticlesService,
    private route: ActivatedRoute,
    public appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) { }

  onNextPage() {
    this.currentPage = this.currentPage + 1;
    this.page$.next(this.currentPage);
  }

  private resetPagination() {
    this.items = [];
    this.haveMore = true;
    this.currentPage = 0;
    this.page$.next(this.currentPage);
  }

  trackListChangingEvents() {
    this.subscriptions
      .push(
        combineLatest([
          this.appSettings.lang$
            .pipe(
              tap((lang) => {
                this.lang = lang;
                this.resetPagination()
              })
            ),
          this.route.paramMap
            .pipe(
              map((params: ParamMap) => params.get('hubId')),
              tap((hubId) => {
                this.hubId = hubId;
                this.resetPagination();
              }),
            ),
          this.page$
        ])
        .pipe(
          tap(() => this.isLoading = true),
          switchMap(([lang, hubId, page]) => this.articles.listHubArticles(lang, hubId, page)),
          tap(({ data: newItems = [] }) => {
            this.isLoading = false;
            if (newItems.length === 0) {
              this.haveMore = false;
            } else {
              this.items = [
                ...this.items,
                ...newItems
              ];
              this.seo.setSeoMetaData(
                undefined,
                this.items
                  .map(getArticleHeader)
                  .map(({ header }) => header)
                  .join(', ')
              )
            }
          })
        )
        .subscribe()
      );
  }

  trackSeoChanges() {
    this.subscriptions.push(
      this.route.paramMap
      .pipe(
        map((params: ParamMap) => params.get('hubId')),
        switchMap((hubId) => this.articles.getHubData(hubId)),
        tap(({ data: [item] }) => {
          this.seo.setSeoMetaData(
            this.translate.transform(item.data.text, this.lang),
            undefined
          )
        }),
      )
      .subscribe()
    )
  }

  trackSectionTopItems() {
    this.subscriptions
      .push(
        combineLatest([
          this.appSettings.lang$,
          this.route.paramMap.pipe(map((params: ParamMap) => params.get('hubId')))
        ])
        .pipe(
          switchMap(([lang, hubId]) => this.articles.listHubArticles(lang, hubId, 0, 'popular')),
          map(({ data: articles }) => articles),
          tap((items) => this.topItems = items)
        )
        .subscribe()
      )
  }

  ngOnInit() {
    this.trackListChangingEvents();
    this.trackSeoChanges();
    this.trackSectionTopItems();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
