import { createAction, props } from '@ngrx/store';
import { ILocalizationSchema } from '../../../interfaces';

export const setLang = createAction(
  '[APP] set app lang',
  props<{ data: string }>()
);

export const setUserAuthorizationFlag = createAction(
  '[APP] set auth flag',
  props<{ data: boolean }>()
);

export const setMainMenuExpantionFlag = createAction(
  '[APP] set main menu expantion flag',
  props<{ data: boolean }>()
);

export const setMobileDeviceFlag = createAction(
  '[APP] set mobile device flag',
  props<{ data: boolean }>()
);

export const setAppLocalization = createAction(
  '[APP] set app localization schema',
  props<{ data: ILocalizationSchema }>()
);

export const setUserMenuFlag = createAction(
  '[APP] set user menu flag',
  props<{ data: boolean }>()
);

export const setSubMenuFlag = createAction(
  '[APP] set sub menu flag',
  props<{ data: boolean }>()
);

export const setPrefferedCurrecny = createAction(
  '[APP] set currency unit',
  props<{ data: string }>()
);
