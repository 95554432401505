import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../common/pipes/pipes.module';
import { ContentModule } from '../../components/content/content.module';
import { ReviewFormModule } from './components/review-form/review-form.module';
import { ArticlesReviewPageComponent } from './articles-review-page.component';

@NgModule({
  declarations: [
    ArticlesReviewPageComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    ContentModule,
    ReviewFormModule
  ]
})
export class ArticlesReviewPageModule { }
