import { IMapped, IRaw } from '@besinor/core'

export const formatUserData = ({ data, votes }: IRaw.IUser): IMapped.IUserProfile => {
  return {
    userFullName: {
      text: `${data.fname} ${data.lname}`,
      urlType: 'none',
      icon: 'fas fa-signature',
    },
    userEducation: {
      text: data.education,
      urlType: 'none',
      icon: 'fas fa-graduation-cap',
    },
    userVotes: {
      text: `${votes}`,
      urlType: 'none',
      icon: 'fas fa-poll',
    },
    userFacebook: {
      text: data.facebook,
      urlType: 'anchor',
      url: data.facebook,
      icon: 'fab fa-facebook-f',
    },
    userTwitter: {
      text: data.twitter,
      urlType: 'anchor',
      url: data.twitter,
      icon: 'fab fa-twitter'
    },
    nickName: {
      text: data.nickName,
      urlType: 'none',
      icon: 'fas fa-person',
    },
    ... data.logo && {
      logo: {
        text: `${data.fname} ${data.lname} (${data.nickName})`,
        url: data.logo,
        urlType: 'hidden',
      }
    }
  }
}

export const filterMissingFields = (data: IMapped.IUserProfile): IMapped.IUserProfile => {

  return Object.keys(data)
    .filter((key) => data[key] && data[key].text && data[key].text !== 'null' && data[key].text !== 'undefined')
    .reduce((acc, item) => ({
      ...acc,
      [item]: data[item],
    }), {});
}
