import { Component, Input } from '@angular/core';
import { IMapped } from '@besinor/core';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent {

  @Input() items: IMapped.IArticleShort[] = [];
}
