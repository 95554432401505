import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-line',
  templateUrl: './logo-line.component.html',
  styleUrls: ['./logo-line.component.scss']
})
export class LogoLineComponent {

}
