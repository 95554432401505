<div class="besinor-article-slider">
  <besinor-article-image
    *ngIf="data.images.length === 1 || !isBrowser"
    [data]="data.images[0]"
    [isMobile]="isMobile"
  ></besinor-article-image>
  <carousel
    *ngIf="isBrowser && data.images.length > 1"
    [cellWidth]="'100%'"
    [height]="carouselHeight"
  >
    <div class="carousel-cell" *ngFor="let img of data.images">
      <besinor-article-image
        [data]="img"
        [isMobile]="isMobile"
        (imageLoaded)="handleImageLoaded($event)"
      ></besinor-article-image>
    </div>
  </carousel>
</div>
