import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonComponentsModule } from '../common/common-components.module';
import { ArticlesModule } from './articles/articles.module';
import { CommentsModule } from './comments/comments.module';
import { PipesModule } from '../../common/pipes/pipes.module';
import { ServicesModule } from '../../common/services/services.module';
import { SideBarComponent } from './side-bar/side-bar.component';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserLoginFormComponent } from './user-login-form/user-login-form.component';
import { UserRegistrationFormComponent } from './user-registration-form/user-registration-form.component';
import { DonateModule } from './donate/donate.module';
import { ControllersModule } from './controllers/controllers.module';
import { UserSignUpLinkComponent } from './user-sign-up-link/user-sign-up-link.component';
import { WriteArticleLinkComponent } from './write-article-link/write-article-link.component';
import { SideLinksComponent } from './side-links/side-links.component';
import { SideShareComponent } from './side-share/side-share.component';

@NgModule({
  declarations: [
    SideBarComponent,
    ArticlesListComponent,
    UserProfileComponent,
    UserLoginFormComponent,
    UserRegistrationFormComponent,
    UserSignUpLinkComponent,
    WriteArticleLinkComponent,
    SideLinksComponent,
    SideShareComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CommonComponentsModule,
    ArticlesModule,
    CommentsModule,
    ServicesModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DonateModule,
    ControllersModule,
    LazyLoadImageModule
  ],
  exports: [
    ArticlesModule,
    CommentsModule,
    SideBarComponent,
    ArticlesListComponent,
    UserProfileComponent,
    UserLoginFormComponent,
    UserRegistrationFormComponent,
    DonateModule,
    ControllersModule,
    UserSignUpLinkComponent,
    WriteArticleLinkComponent,
    SideLinksComponent,
    SideShareComponent
  ]
})
export class ContentModule { }
