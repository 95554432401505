import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IRaw, IMapped } from '@besinor/core';
import { SeoService } from '../../common/services/seo.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { formatUserData, filterMissingFields } from '../../common/helpers/users';
import { getArticleHeader } from '../../common/helpers/articles';
import { ArticlesService } from '../../common/services/articles.service';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: [
    '../../components/sharedStyles.scss',
    '../sharedStyles.scss',
    './article-page.component.scss'
  ]
})
export class ArticlePageComponent implements OnInit, OnDestroy {

  public articles: IRaw.IArticle[] = [];
  public topArticles: IRaw.IArticle[] = [];
  public isDonationAllowed: boolean = false;
  public isDiscussionAllowed: boolean = false;
  public userData: IMapped.IUserProfile;
  public articleId: string;
  public lang: string;

  public isCreatingInvoice: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    public settings: AppSettingsSelectorsService,
    private seo: SeoService
  ) { }

  async ngOnInit() {
    this.subscriptions.push(
      this.route.paramMap
        .pipe(
          map((params: ParamMap) => params.get('articleId')),
          tap((articleId: string) => this.articleId = articleId),
          switchMap((articeId) => this.articlesService.articleById(articeId)),
          map(({ data: articles }) => articles),
          tap((articles) => {
            this.articles = articles;
            this.seo.setPageSeoFromArticle(getArticleHeader(articles[0]));
          }),
          tap(() => {
            const article = this.articles[0];
            this.isDiscussionAllowed = article.comment;
            this.isDonationAllowed = article.donation;
            this.userData = filterMissingFields(formatUserData(article.user));
          }),
          switchMap(() => this.articlesService.listSimilarArticles(this.articleId, 0)),
          map(({ data: articles }) => articles),
          tap((articles) => this.topArticles = articles)
        )
        .subscribe()
    );

    this.subscriptions
      .push(
        this.settings.lang$
          .subscribe(lang => this.lang = lang)
      );
  }

  ngOnDestroy() {
    this.subscriptions
      .forEach(sub => sub.unsubscribe())
  }

}
