import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { ArticleModule } from './article/article.module';
import { ArticleShortComponent } from './article-short/article-short.component';

@NgModule({
  declarations: [
    ArticleShortComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    ArticleModule,
    RouterModule
  ],
  exports: [
    ArticleModule,
    ArticleShortComponent,
  ]
})
export class ArticlesModule { }
