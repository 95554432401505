import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRaw } from '@besinor/core';
import { ArticlesService } from '../../common/services/articles.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { SIGN_UP_PAGE_TIPS } from './constants';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './sign-up.component.scss'
  ]
})
export class SignUpComponent implements OnInit, OnDestroy {

  @Input() lang: string;
  public articles: IRaw.IArticle[];
  public form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private articlesService: ArticlesService,
    public appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {
    this.form = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.email]),
      fname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lname: new FormControl('', [Validators.required, Validators.minLength(2)])
    });
  }

   ngOnInit(): void {
    this.subscriptions.push(
      this.appSettings
        .lang$
        .pipe(
          tap((lang) => {
            this.seo.setSeoMetaData(
              this.translate.transform('seoSignUpTitle', lang),
              this.translate.transform('seoSignUpDesciption', lang)
            );
          }),
          switchMap(lang => this.articlesService.listGroupById(SIGN_UP_PAGE_TIPS[lang])),
          map(({ data: [ group ] }) => group.articles),
          tap((articles) => this.articles = articles)
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
