import { Component, Input } from '@angular/core';
import { IMapped } from '@besinor/core';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent {

  @Input() items: IMapped.IArticleShort[] = [];
  @Input() isMobile: boolean = true;
}
