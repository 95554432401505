import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServicesModule } from '../../common/services/services.module';
import { CommonComponentsModule } from '../common/common-components.module';
import { PipesModule } from '../../common/pipes/pipes.module';
import { SocialShortcutsComponent } from './social-shortcuts/social-shortcuts.component';
import { LogoComponent } from './logo/logo.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { TopComponent } from './top.component';
import { LangChangerComponent } from './lang-changer/lang-changer.component';

@NgModule({
  declarations: [
    SocialShortcutsComponent,
    LogoComponent,
    MainMenuComponent,
    TopComponent,
    BreadCrumbsComponent,
    LangChangerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ServicesModule,
    CommonComponentsModule,
    PipesModule
  ],
  exports: [
    TopComponent,
    BreadCrumbsComponent
  ]
})
export class TopModule { }
