<ng-container *ngIf="!hidden">
  <img
    class="article-mainImg"
    [defaultImage]="'/assets/img/backgrounds/placeholder.svg'"
    [lazyLoad]="isMobile ? data.img.src_mobile : data.img.src_desktop"
    *ngIf="data.img"
    [alt]="data.header"
  />
  <div class="article-infos">
    <span class="article-info" *ngIf="votes !== 0"><i class="fas fa-poll"></i> {{ votes }}</span>
    <a [routerLink]="user.url" *ngIf="user" class="article-info">{{ user.data.nickName }}</a>
    <span class="article-info" *ngIf="modified">{{ modified | date }}</span>
    <a *ngFor="let hub of (ASYNC_DATA(hubs) | async)" [routerLink]="hub.url" class="article-info">{{ hub.data.text | translate:lang }}</a>
  </div>
  <h4 class="article-header">{{ data.header }}</h4>
  <p class="article-body" *ngIf="!skipOverview">{{ data.text }} ...</p>
</ng-container>
