<div class="topmenu">
  <a
    [routerLink]="landingPage"
    class="topmenu-link"
  >
    <img src="/assets/logo.png" alt="besinor.net" class="topmenu-img" />
  </a>
  <div class="topmenu-icons">
    <!-- <div class="topmenu-icons-icon">
      <a [routerLink]="userProfileLink">
        <i
          data-besinor="menu-expander"
          class="fas"
          [ngClass]="{ 'fa-user': userAuthorizationFlag, 'fa-sign-in-alt': !userAuthorizationFlag }"
        >
        </i>
      </a>
    </div> -->
    <div class="topmenu-icons-icon" (click)="expandMenu()">
      <i
        data-besinor="menu-expander"
        class="fas fa-bars"
        [ngClass]="{'fa-window-close': mainMenuExpantionFlag }"
      >
      </i>
    </div>
  </div>
</div>
