<form novalidate (ngSubmit)="onSubmit(form)" [formGroup]="form">
  <p class="input">
    <span class="input-wrapper">
      <input
        dir="ltr"
        autocomplete="off"
        formControlName="login"
        type="email"
        name="login"
        size="40"
        class="input-wrapper-input email"
        aria-invalid="false"
        [placeholder]="'email' | translate:lang"
      />
    </span>
  </p>
  <p class="input">
    <span class="input-wrapper">
      <input
        dir="ltr"
        autocomplete="off"
        formControlName="password"
        type="password"
        name="password"
        size="40"
        class="input-wrapper-input email"
        aria-invalid="false"
        [placeholder]="'password' | translate:lang"
      />
    </span>
  </p>
  <p class="button">
    <button
      type="submit"
      class="button-submit"
      [disabled]="(form.invalid || submitting)"
      [ngClass]="{'disabled': form.invalid || submitting}"
    >
      {{ (submitting ? 'working' : 'authorize') | translate:lang }}
    </button>
  </p>
</form>
