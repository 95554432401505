import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-sign-up-link',
  templateUrl: './user-sign-up-link.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './user-sign-up-link.component.scss'
  ]
})
export class UserSignUpLinkComponent {

  @Input() lang: string;
  public signUpLink = () => `/${this.lang}/sign-up`;

}
