import { Component, EventEmitter, Output, Input } from '@angular/core';
import { IDonationItem } from './interfaces';

@Component({
  selector: 'app-donation-selector',
  templateUrl: './donation-selector.component.html',
  styleUrls: [
    '../../../sharedStyles.scss',
    '../sharedStyles.scss'
  ]
})
export class DonationSelectorComponent {

  @Output() amountSelected: EventEmitter<number> = new EventEmitter<number>();
  @Input() lang: string;
  @Input() isLoading: boolean = false;
  @Input() items: IDonationItem[] = [];
  @Input() currency: string = 'EUR';

  public selectedAmount: IDonationItem;

  selectAmount(item: IDonationItem) {
    this.selectedAmount = item;
  }

  cancelSelectedItem() {
    this.selectedAmount = undefined;
  }

  proceedWithAmount(amount: number) {
    this.amountSelected.emit(amount);
  }

}
