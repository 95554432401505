<div class="article" [dir]="data.lang | langDir">
  <a [routerLink]="data.url">
    <besinor-article-header
      [data]="data.data"
      [hubs]="data.hubs"
      [modified]="data.modified"
      [lang]="data.lang"
      [user]="data.user"
      [isMobile]="isMobile"
      [votes]="data.votes"
    ></besinor-article-header>
  </a>
</div>
