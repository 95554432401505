<div class="social" appLangDirection>
  <a
    *ngFor="let item of items"
    [href]="item.url"
    class="social-link"
    target="_blank"
    rel="noopener"
  >
    <div class="social-icon">
      <i class="fab icon" [ngClass]="item.icon"></i>
    </div>
  </a>
</div>
