import { createSelector } from '@ngrx/store';
import { IAppState, IAppPageSettings } from '../../../interfaces';

export const selectApp = (state: IAppState) => state.app;

export const appSupportedLangs = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.langs
);

export const appL10n = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.l10n
);

export const appLang = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.lang
);

export const appDir = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.dir
);

export const userAuthorizationFlag = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.isAuthorized
);

export const mainMenuExpantionFlag = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.isNavMenuExpanded
);

export const mobileDeviceFlag = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.isMobileDevice
);

export const userMenuFLag = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.isUserMenuShown
);

export const subMenuFLag = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.isSubMenuShown
);

export const currency = createSelector(
  selectApp,
  (state: IAppPageSettings) => state.currency
);
