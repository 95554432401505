import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-load-more-results',
  templateUrl: './load-more-results.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './load-more-results.component.scss'
  ]
})
export class LoadMoreResultsComponent {

  @Input() loadMoreText: string = 'loadMoreResults';
  @Input() lang: string;
  @Input() haveMore: boolean = true;
  @Input() isLoading: boolean = false;
  @Output() nextPage: EventEmitter<void> = new EventEmitter<void>();

  emitButtunClicked() {
    this.nextPage.emit();
  }

}
