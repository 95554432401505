<div class="component" *ngIf="items.length > 0">
  <div class="component-header" appLangDirection>
    <h4 class="component-header-text" >{{ header }}</h4>
  </div>
  <div class="component-items">
    <div class="component-item" *ngFor="let item of items">
      <a class="component-item-text padd-20" [routerLink]="item.url" >
        {{ item.data.header }}
      </a>
    </div>
  </div>
</div>
