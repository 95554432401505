import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../components/footer/footer.module';
import { TopModule } from '../components/top/top.module';
import { ContentModule } from '../components/content/content.module';
import { routes } from './routes';
import { LayoutsWrapperComponent } from './layouts-wrapper.component';

@NgModule({
  declarations: [
    LayoutsWrapperComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
}),
    ContentModule,
    FooterModule,
    TopModule
  ],
  exports: [
    RouterModule,
    LayoutsWrapperComponent,
    ContentModule,
    FooterModule,
    TopModule
  ]
})
export class AppRoutingModule { }
