import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private settings: SettingsService) { }

  transform(code: string, lang: string = this.settings.lang): string {
    return this.settings.l10n[lang] && this.settings.l10n[lang][code] ?
      this.settings.l10n[lang][code] :
      `${code} - not transated`;
  }
}
