import { createSelector } from '@ngrx/store';
import { IAppState, IVanigationItems } from '../../../interfaces';

export const selectApp = (state: IAppState) => state.navigation;

export const socialLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.socialLinks
);

export const langLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.langLinks
);

export const mainMenuLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.mainMenuLinks
);

export const subMenuLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.subMenuLinks
);

export const footerLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.footerLinks
);

export const userMenuLinks = createSelector(
  selectApp,
  (state: IVanigationItems) => state.userMenuLinks
);
