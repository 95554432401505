export const donationItems = [
  {
    amount: 1,
    icon: 'far fa-money-bill-alt fa-2x',
    name: 'donateACoin'
  },
  {
    amount: 3,
    icon: 'fas fa-coffee fa-2x',
    name: 'buyACupOfTea'
  },
  {
    amount: 5,
    icon: 'fas fa-beer fa-2x',
    name: 'buyABeer'
  },
  {
    amount: 7,
    icon: 'fas fa-award fa-2x',
    name: 'assignAward'
  },
  {
    amount: 10,
    icon: 'fas fa-crown fa-2x',
    name: 'assignCrown'
  }
];
