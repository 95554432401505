import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { IRaw } from '@besinor/core';
import { ArticlesService } from '../../common/services/articles.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { SIGN_IN_PAGE_TIPS } from './constants';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './sign-in.component.scss'
  ]
})
export class SignInComponent implements OnInit {

  public articles: IRaw.IArticle[];
  private subscriptions: Subscription[] = [];

  constructor(
    private articlesService: ArticlesService,
    public appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.appSettings
        .lang$
        .pipe(
          tap((lang) => {
            this.seo.setSeoMetaData(
              this.translate.transform('seoSignInTitle', lang),
              this.translate.transform('seoSignInDesciption', lang)
            );
          }),
          switchMap(lang => this.articlesService.listGroupById(SIGN_IN_PAGE_TIPS[lang])),
          map(({ data: [ group ] }) => group.articles),
          tap((articles) => this.articles = articles)
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
