import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langDir'
})
export class LangDirPipe implements PipeTransform {

  private rtlLangs = {
    'ar': 'rtl'
  };

  transform(lang: string): string {
    return this.rtlLangs[lang] || 'ltr';
  }

}
