<div class="navmenu">
  <ul
    class="navmenu-ul"
    (click)="expandMenu()"
    *ngIf="mainMenuExpantionFlag"
  >
    <li *ngFor="let item of items" class="navmenu-li">
      <a
        [routerLink]="item.url"
        [routerLinkActive]="['active']"
        class="navmenu-link">{{ item.data.text | translate:lang }}</a>
    </li>
  </ul>
  <!-- <div class="navmenu-icons">
    <div class="navmenu-icons-icon">
      <a [routerLink]="userProfileLink">
        <i
          class="fas"
          [ngClass]="{ 'fa-user': userAuthorizationFlag, 'fa-sign-in-alt': !userAuthorizationFlag }"
        ></i>
      </a>
    </div>
  </div> -->
</div>
