import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { IRaw } from '@besinor/core';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  private setTitle(text: string) {
    this.title.setTitle(text);
  }

  private setDescription(text: string) {
    this.meta.updateTag({ name: 'description', content: text });
  }

  private setCustonMeta(name: string, value: string) {
    this.meta.updateTag({ name, content: value });
  }

  setSeoMetaData(title: string, descr: string) {
    if (title) {
      this.setTitle(title);
    };
    if (descr) {
      this.setDescription(descr);
    }
  }

  private setOgMetaData(title: string, description: string, img?: string) {
    this.setCustonMeta('og:title', title);
    this.setCustonMeta('og:site_name', 'BESINOR.NET');
    this.setCustonMeta('og:description', description || title);
    if (img) {
      this.setCustonMeta('og:image', img);
    }
  }

  public setPageSeoFromArticle(article: IRaw.IArticleMainParts): void {
    this.setPageMetaTags(article.header, article.text,  article.img && article.img.src_mobile);
  }

  public setPageMetaTags(title: string, description: string, imgUrl?: string): void {
    this.setSeoMetaData(title, description);
    this.setOgMetaData(title, description, imgUrl);
  }
}
