import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ARTICLE, IMapped } from '@besinor/core';
import { of } from 'rxjs';

@Component({
  selector: 'besinor-article',
  templateUrl: './article.component.html',
  styleUrls: [
    '../quillEditor.css',
    '../quillEditorPatch.scss',
    '../sharedStyles.scss',
    './article.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ArticleComponent {
  public article: IMapped.IArticle;
  @Input() data: IMapped.IArticle;
  @Input() noHeader: boolean = false;
  @Input() isMobile: boolean = true;

  public HEADER = ARTICLE.HEADER;
  public PARAGRAPH = ARTICLE.PARAGRAPH;
  public IMAGE = ARTICLE.IMAGE;
  public CITE = ARTICLE.CITE;
  public SLIDER = ARTICLE.SLIDER;

  public ASYNC_DATA = (data) => of(data);
}
