import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, EMPTY } from 'rxjs';
import { filter, switchMap, tap, catchError } from 'rxjs/operators';
import { DeviceTypeService } from './common/services/device-type.service';
import { SettingsService } from './common/services/settings.service';
import { AppSettingsSelectorsService } from './common/state/app/app-settings-selectors.service';
import { ESC, ANALYTICS_GOOGLE_KEY } from './constants';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @HostListener('window:resize')
  onWindowResize() {
    this.deviceType.resizeHandler();
  }

  @HostListener('window:load')
  onWindowLoad() {
    this.deviceType.resizeHandler();
  }

  @HostListener('window:click', ['$event'])
  onGlobalClick({ target: { dataset = {}}}) {
    if (!Object.values(dataset).includes('menu-expander')) {
      this.expandNavMenu();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onGlobalKeyDown({ keyCode }) {
    if (keyCode === ESC) {
      this.expandNavMenu();
    }
  }

  constructor(
    private deviceType: DeviceTypeService,
    private settings: SettingsService,
    private router: Router,
    private appSettings: AppSettingsSelectorsService
  ) { }

  expandNavMenu() {
    this.appSettings.setMainMenuExpantionFlag(false);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.deviceType
      .isMobile
      .subscribe(isMobile => {
        this.appSettings.setMobileDevieFlag(isMobile);
      })
    );

    this.subscriptions.push(
      this.router
        .events
        .pipe(
          filter((event) => Boolean(event instanceof NavigationEnd)),
          switchMap(() => this.settings.setUserStatus()),
          catchError(() => EMPTY)
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.router
        .events
        .pipe(
          filter((event) => Boolean(event instanceof NavigationEnd)),
          tap((event: NavigationEnd) => {
            if (this.deviceType.isBrowser) {
              gtag('config', ANALYTICS_GOOGLE_KEY, { 'page_path': event.urlAfterRedirects });
            }
          })
        )
        .subscribe()
    );

    this.deviceType.resizeHandler();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
