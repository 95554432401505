import { Injectable } from '@angular/core';
import { Subscription, Observable, throwError, combineLatest } from 'rxjs';
import { tap, map, filter, catchError } from 'rxjs/operators';
import { IResponse, IRaw, DEFAULT_UI_LANG, LANG_AR, LANG_KU } from '@besinor/core';
import { convertToShortInternalArticles } from '../helpers/articles';
import { AppSettingsSelectorsService } from '../state/app/app-settings-selectors.service';
import { NavSettingsSelectorsService } from '../state/navigation/nav-settings-selectors.service';
import { ArticlesService } from './articles.service';
import { UsersService } from './users.service';
import { HUBS_PER_STRESM} from '../constants';
import { ILocalizationSchema } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public l10n: ILocalizationSchema = {};
  public lang: string = DEFAULT_UI_LANG;
  private subscriptions: Subscription[] = [];

  constructor(
    private appSettings: AppSettingsSelectorsService,
    private userService: UsersService,
    private articlesService: ArticlesService,
    private navService: NavSettingsSelectorsService
  ) {
    // setting public localization schema and lang as the change
    this.subscriptions.push(
      combineLatest([
        this.appSettings.pageLcoalizationSchema$,
        this.appSettings.lang$
      ])
      .pipe(
        tap(([schema, lang]) => {
          this.l10n = schema;
          this.lang = lang;
        })
      )
      .subscribe()
    );
  }

  public setTopStringsAsMainMenu(limit: number = 5): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.articlesService
      .getTopStreams(limit)
      .pipe(
        tap(({ data: items }) => this.navService.setMainMenuLinks(items))
      ) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  };

  public setTopHubsAsSubMenu(lang: string, limit: number = HUBS_PER_STRESM): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.articlesService
      .getTopHubs(lang, limit)
      .pipe(
        tap(({ data: items }) => this.navService.setSubMenuLinks(items))
      ) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  };

  public setL10n(fileId: string): Observable<IResponse<IRaw.ILocalization[]>> {

    return this.articlesService.getL10nItemById(fileId)
      .pipe(
        tap(({ data: [item] }) => {
          const schema: ILocalizationSchema = item.data.definitions;
          const langLinks = item
            .data
            .langs
            .filter(({ id }) => [LANG_AR, LANG_KU].includes(String(id)))
            .map(lang => ({
              ...lang,
              browseUrl: `/${lang.id}/landing`
            }));
          this.navService.setLangLinks(langLinks);
          this.appSettings.setPageLcoalizationSchema(schema);
        })
      ) as Observable<IResponse<IRaw.ILocalization[]>>;
  }

  public setGroupArticlesAsFooterItems(groupId: string): Observable<IRaw.IArticlesGroup[]> {

    return this.articlesService
      .listGroupById(groupId)
      .pipe(
        filter(({ data }) => Boolean(Array.isArray(data) && data.length)),
        tap(({ data: [item] }) => {
          const links = item.articles && item.articles.map((article) =>
            convertToShortInternalArticles(this.lang, article)
          );
          this.navService.setFooterLinks(links);
        }),
        map(({ data }) => data)
      );
  }

  setUserStatus(): Observable<IResponse<boolean>> {

    return this.userService
      .validateJwt()
      .pipe(
        map(({ data }): boolean => data),
        map(Boolean),
        tap(isAuthorized => this.appSettings.setUserAuthorizationFlag(Boolean(isAuthorized))),
        catchError((error) => {
          this.appSettings.setUserAuthorizationFlag(false);

          return throwError(error);
        })
      ) as Observable<IResponse<boolean>>;
  }
}
