import { Component, OnInit, Input } from '@angular/core';
import { DEFAULT_UI_LANG } from '@besinor/core';
import { ILang } from '../../../interfaces';

@Component({
  selector: 'app-lang-changer',
  templateUrl: './lang-changer.component.html',
  styleUrls: ['./lang-changer.component.scss']
})
export class LangChangerComponent{

  @Input() items: ILang[] = [];
  @Input() lang: string = DEFAULT_UI_LANG;
}
