<div class="langs">
  <div class="langs-conteinr">
    <div *ngFor="let item of items" class="langs-links">
      <a
        [routerLink]="item.browseUrl"
        [routerLinkActive]="['active']"
        class="langs-links-link">{{ item.id | translate:lang }}</a>
    </div>
  </div>
</div>
