import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../common/pipes/pipes.module';
import { CommonComponentsModule } from '../components/common/common-components.module';
import { FooterModule } from '../components/footer/footer.module';
import { TopModule } from '../components/top/top.module';
import { ContentModule } from '../components/content/content.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HubPageComponent } from './hub-page/hub-page.component';
import { StreamPageComponent } from './stream-page/stream-page.component';
import { ArticlePageComponent } from './article-page/article-page.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpActivationComponent } from './sign-up-activation/sign-up-activation.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ProfileModule } from './profile/profile.module';
import { UserPageComponent } from './user-page/user-page.component';
import { AmountSelectorPageComponent } from './article-page/components/amount-selector-page/amount-selector-page.component';
import { AmountPaymentConfirmationPageComponent } from './article-page/components/amount-payment-confirmation-page/amount-payment-confirmation-page.component';
import { ArticlePreviewPageComponent } from './article-preview-page/article-preview-page.component';
import { ArticlesReviewPageModule } from './articles-review-page/articles-review-page.module';
import { UserNewPasswordComponent } from './user-new-password/user-new-password.component'

@NgModule({
  declarations: [
    LandingPageComponent,
    HubPageComponent,
    StreamPageComponent,
    ArticlePageComponent,
    SignUpComponent,
    SignUpActivationComponent,
    SignInComponent,
    UserPageComponent,
    AmountSelectorPageComponent,
    AmountPaymentConfirmationPageComponent,
    ArticlePreviewPageComponent,
    UserNewPasswordComponent
  ],
  imports: [
    CommonModule,
    ContentModule,
    FooterModule,
    TopModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileModule,
    RouterModule,
    PipesModule,
    ArticlesReviewPageModule,
  ],
  exports: [
    LandingPageComponent,
    HubPageComponent,
    StreamPageComponent,
    ArticlePageComponent
  ]
})
export class LayoutsModule { }
