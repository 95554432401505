import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { tap, map, catchError, switchMap, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRaw } from '@besinor/core';
import { ToastrService } from 'ngx-toastr';
import { ArticlesService } from '../../common/services/articles.service';
import { UsersService } from '../../common/services/users.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { NEW_PASSWORD_PAGE_TIPS } from './constants';
import { CP_HOST } from 'src/app/constants';

@Component({
  selector: 'app-user-new-password',
  templateUrl: './user-new-password.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    '../../components/sharedStyles.scss', // this is taken from common styles
    './user-new-password.component.scss',
  ]
})
export class UserNewPasswordComponent implements OnInit {

  public articles: IRaw.IArticle[];
  public form: FormGroup;
  public submitting: Boolean = false;
  public lang: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private articlesServices: ArticlesService,
    private user: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(7)]),
    });
  }

  public onSubmit(form: FormGroup): void {
    this.submitting = true;

    this.subscriptions.push(
      combineLatest([
        this.route.paramMap.pipe(map((params: ParamMap) => params.get('key'))),
        this.appSettings.lang$
      ])
        .pipe(
          map(([key, lang]) => ({ key, lang })),
          switchMap(({ key, lang }) => this.user.createNewPassword(key, { password: form.value.password, lang })),
          tap(() => {
            this.toaster.success('OK', 'Password changed successfully');
            this.router.navigate(['/']);
          }),
          catchError(e => {
            this.submitting = false;
            this.toaster.error('INVALID VALIDATION KEY', 'Or your password might have been changed already');

            return e;
          })
        )
        .subscribe()
    )
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.appSettings
        .lang$
        .pipe(
          tap((lang) => {
            this.lang = lang;
            this.seo.setSeoMetaData(
              this.translate.transform('seoActivationTitle', lang),
              this.translate.transform('seoActivationDesciption', lang)
            );
          }),
          switchMap(lang => this.articlesServices.listGroupById(NEW_PASSWORD_PAGE_TIPS[lang])),
          map(({ data: [ group ] }) => group.articles),
          tap((articles) => this.articles = articles)
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
