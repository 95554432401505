import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DEFAULT_UI_LANG } from '@besinor/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  public userProfileLink: string;
  public landingPage: string;

  @Input('lang')
  set lang(lang: string) {
    this.userProfileLink = `/${lang}/profile`;
    this.landingPage = `/${lang}`;
  }

  @Input() mainMenuExpantionFlag: boolean = false;
  @Input() userAuthorizationFlag: boolean = false;

  @Output() mainMenuExpanderClicked: EventEmitter<void> = new EventEmitter<void>();

  public expandMenu() {
    this.mainMenuExpanderClicked.emit();
  }
}
