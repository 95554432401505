import { Pipe, PipeTransform } from '@angular/core';
import { IMapped, IRaw, DEFAULT_UI_LANG } from '@besinor/core';
import { formatStramItem, formatHubItem } from '../helpers/navigation';

@Pipe({
  name: 'mapStreamItems'
})
export class MapNavStreamItemsPipe implements PipeTransform {
  transform(items: IRaw.INavigationStreamItem[], lang: string = DEFAULT_UI_LANG): IMapped.INavigationStreamItem[] {
    return items.map(item => formatStramItem(lang, item));
  }
}

@Pipe({
  name: 'mapHubItems'
})
export class MapNavHubItemsPipe implements PipeTransform {
  transform(items: IRaw.INavigationHubItem[], lang: string = DEFAULT_UI_LANG): IMapped.INavigationHubItem[] {
    return items.map(item => formatHubItem(lang, item));
  }
}
