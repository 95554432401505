import { Injectable } from '@angular/core';
import { RestApiService } from '@besinor/services';
import { Observable } from 'rxjs';
import { IRaw, IResponse } from '@besinor/core';
import { HOST } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private http: RestApiService) { }

  public getPendingInvoiceById(invoiceId: string): Observable<IResponse<IRaw.Invoice>> {

    return this.http.get(`${HOST}/payments/pending/${invoiceId}`) as Observable<IResponse<IRaw.Invoice>>;
  }

  public createStripePaymentSession(invoiceId: string, data: any): Observable<IResponse<any>> {

    return this.http.put(`${HOST}/payments/partners/stripe/pay/${invoiceId}`, data) as Observable<IResponse<any>>;
  }

  public createInvoice(articleId: string, amount: string, currency: string): Observable<IResponse<string>> {

    return this.http.put(`${HOST}/payments/signed-in/${articleId}`, { amount, currency }) as Observable<IResponse<string>>;
  }

  public createInvoiceAnanymously(articleId: string, amount: string, currency : string ): Observable<IResponse<string>> {

    return this.http.put(`${HOST}/payments/anonymous/${articleId}`, { amount, currency }) as Observable<IResponse<string>>;
  }

  public makePayment(invoiceId: string, data: any): Observable<IResponse<string>> {

    return this.http.put(`${HOST}/payments/makePayment/${invoiceId}`, data) as Observable<IResponse<string>>;
  }

  public getReciepeById(reciepeId: string): Observable<IResponse<IRaw.IPaymentTransaction>> {

    return this.http.get(`${HOST}/payments/reciepe/${reciepeId}`) as Observable<IResponse<IRaw.IPaymentTransaction>>;
  }

}
