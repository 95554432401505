import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IRaw, IMapped } from '@besinor/core';
import { SeoService } from '../../common/services/seo.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { getArticleHeader } from '../../common/helpers/articles';
import { ArticlesService } from '../../common/services/articles.service';

@Component({
  selector: 'app-articles-review-page',
  templateUrl: './articles-review-page.component.html',
  styleUrls: [
    '../../components/sharedStyles.scss',
    '../sharedStyles.scss',
    './articles-review-page.component.scss'
  ]
})
export class ArticlesReviewPageComponent implements OnInit {

  public articles: IRaw.IArticle[] = [];
  public topArticles: IMapped.IArticleShort[] = [];
  public lang: string;
  public isCreatingInvoice: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    public settings: AppSettingsSelectorsService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.route.paramMap
        .pipe(
          map((params: ParamMap) => params.get('articleId')),
          switchMap((articleId) => this.articlesService.articleByIdForPreview(articleId)),
          map(({ data: articles }) => articles),
          tap((articles) => {
            this.articles = articles;
            this.seo.setPageSeoFromArticle(getArticleHeader(articles[0]));
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.settings
        .lang$
        .subscribe(lang => this.lang = lang)
    );
  }

  ngOnDestroy() {
    this.subscriptions
      .forEach(sub => sub.unsubscribe())
  }

}
