import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  private headers(): any {

    return {
      'Authorization': `Bearer ${this.cookieService.get('token')}`
    }
  }

  public setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict'): void {
    this.cookieService.set(name, value, expires, path, domain, secure, sameSite);
  }

  public getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  public get<T>(url: string): Observable<T> {

    const options = {
      headers: this.headers()
    };

    return this.http.get<T>(url, options) as Observable<T>;
  }

  public post<T>(url: string, payload: any): Observable<T> {

    const options = {
      headers: this.headers()
    };

    return this.http.post<T>(url, payload, options) as Observable<T>;
  }

  public put<T>(url: string, payload: any): Observable<T> {

    const options = {
      headers: this.headers()
    };

    return this.http.put<T>(url, payload, options) as Observable<T>;
  }

  public patch<T>(url: string, payload: any): Observable<T> {

    const options = {
      headers: this.headers()
    };

    return this.http.patch<T>(url, payload, options) as Observable<T>;
  }

  public delete<T>(url: string): Observable<T> {

    const options = {
      headers: this.headers()
    };

    return this.http.delete<T>(url, options) as Observable<T>;
  }
}
