<div class="container authorization">
  <div class="col">
    <besinor-article
      *ngIf="articles"
      [data]="articles | normalizeArticles | firstItemInArray"
      [noHeader]="true"
    ></besinor-article>
  </div>
  <div class="col form">
    <form novalidate (ngSubmit)="onSubmit(form)" [formGroup]="form">
      <p class="input">
        <span class="input-wrapper">
          <input
            dir="ltr"
            autocomplete="off"
            formControlName="activationKey"
            type="text"
            name="activationKey"
            size="40"
            class="input-wrapper-input"
            aria-invalid="false"
            [value]="'activationKey' | translate:lang"
          />
        </span>
      </p>
      <p class="button">
        <input
          type="submit"
          [value]="'activateMyAccountNow' | translate:lang"
          class="button-submit"
          [ngClass]="{'disabled': form.invalid || submitting}"
        >
      </p>
    </form>
  </div>
</div>
