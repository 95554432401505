<div class="container" appLangDirection>
  <div class="articles">
    <app-articles-list
      [items]="articles | normalizeShortArticles"
      [isMobile]="settings.mobileDeviceFlag$ | async"
    ></app-articles-list>
    <besinor-article
      [data]="articles | normalizeArticles | firstItemInArray"
      [isMobile]="settings.mobileDeviceFlag$ | async"
    ></besinor-article>
  </div>
  <div class="sidebar">
    <div class="float-wrapper">
      <div class="float">
        <app-review-form
          [lang]="lang"
          [article]="articles | firstItemInArray"
        ></app-review-form>
      </div>
    </div>
  </div>
</div>
