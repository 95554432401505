<div class="component user">
  <div class="component-header" *ngIf="header" appLangDirection>
    <h2 class="component-header-text" >{{ header }}</h2>
  </div>
  <div class="component-items" *ngIf="data">
    <div class="user-logo">
      <div *ngIf="data && data.logo && data.logo.url" class="user-logo-wrapper">
        <img
          [defaultImage]="'/assets/img/backgrounds/placeholder.svg'"
          [lazyLoad]="data.logo.url"
          [alt]="data.logo.text"
        />
      </div>
    </div>
    <ng-container *ngFor="let key of profilerKeys">
      <div
        class="component-item"
        *ngIf="data[key] && data[key].urlType !== 'anchor' && data[key].urlType !== 'hidden'"
      >
        <div class="component-item-header">
          <a [routerLink]="data[key].url" *ngIf="data[key].urlType === 'angular'">
            <p class="component-item-header-text">
              <i class="component-item-icon" [ngClass]="data[key].icon" *ngIf="data[key].icon"></i>
              &nbsp;&nbsp;&nbsp;{{ data[key].text }}
            </p>
          </a>
          <p class="component-item-header-text" *ngIf="data[key].urlType === 'none'">
            <i class="component-item-icon" [ngClass]="data[key].icon" *ngIf="data[key].icon"></i>
            &nbsp;&nbsp;&nbsp;{{ data[key].text }}
          </p>
        </div>
      </div>
    </ng-container>
    <div class="social-icons-wrapper">
      <ng-container *ngFor="let key of socialKeys">
        <a class="social-icon" [href]="data[key].text" target="_blank" *ngIf="data[key]">
          <i class="component-item-icon" [ngClass]="data[key].icon"></i>
        </a>
      </ng-container>
    </div>
  </div>
</div>
