import { Component, Input } from '@angular/core';
import { IMapped, DEFAULT_UI_LANG } from '@besinor/core';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent {
  @Input() items: IMapped.INavigationHubItem[] = [];
  @Input() lang: string = DEFAULT_UI_LANG;
}
