import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceTypeService } from './device-type.service';
import { SettingsService } from './settings.service';
import { ArticlesService } from './articles.service';
import { UsersService } from './users.service';
import { InvoicesService } from './invoices.service';
import { SeoService } from './seo.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DeviceTypeService,
    SettingsService,
    ArticlesService,
    UsersService,
    InvoicesService,
    SeoService
  ]
})
export class ServicesModule { }
