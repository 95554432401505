<form (ngSubmit)="onSubmit(form)" [formGroup]="form">
  <p class="input">
    <span class="input-wrapper">
      <input
        dir="ltr"
        autocomplete="off"
        formControlName="login"
        type="email"
        name="login"
        size="40"
        class="input-wrapper-input email"
        aria-invalid="false"
        [placeholder]="'email' | translate:lang"
      />
    </span>
    <span class="input-error" *ngIf="form.get('login').errors && form.touched">
      {{ 'emailRequired' | translate:lang }}
    </span>
  </p>
  <p class="input">
    <span class="input-wrapper">
      <input
        dir="ltr"
        autocomplete="off"
        formControlName="nickName"
        type="text"
        name="nickName"
        size="40"
        class="input-wrapper-input"
        aria-invalid="false"
        [placeholder]="'nickName' | translate:lang"
      />
    </span>
    <span class="input-error" *ngIf="form.get('nickName').errors && form.touched">
      {{ 'nickNameRequired' | translate:lang }}
    </span>
  </p>
  <p class="button">
    <button
      type="submit"
      class="button-submit"
      [ngClass]="{'disabled': form.invalid || submitting}"
    >
      {{ (submitting ? 'working' : 'register') | translate:lang }}
    </button>
  </p>
</form>
