import { Pipe, PipeTransform } from '@angular/core';
import { IMapped, IRaw } from '@besinor/core';
import {
  convertToInternalArticles,
  convertToShortInternalArticles,
  filterMalformattedItems
} from '../helpers/articles';

@Pipe({
  name: 'normalizeShortArticles'
})
export class MapArticleItemsToShortPipe implements PipeTransform {
  transform(articles: IRaw.IArticle[]): IMapped.IArticleShort[] {

    return filterMalformattedItems(articles)
      .map((item) => convertToShortInternalArticles(item.lang, item));
  }
}

@Pipe({
  name: 'normalizeArticles'
})
export class MapArticleItemsPipe implements PipeTransform {
  transform(articles: IRaw.IArticle[]): IMapped.IArticle[] {

    return filterMalformattedItems(articles)
      .map((item) => convertToInternalArticles(item.lang, item));
  }
}

@Pipe({
  name: 'firstItemInArray'
})
export class ReturnFirstArticlePipe implements PipeTransform {
  transform(articles: any[] = []): any {

    return articles[0]
  }
}

@Pipe({
  name: 'seoStructuredData'
})
export class ToStructuredData implements PipeTransform {
  transform(article: IRaw.IArticle): any {
    const mappedArticle = convertToShortInternalArticles(article.lang, article)

    const dl = {
      "@context": "https://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": mappedArticle.url,
      },
      "headline": mappedArticle.data.header,
      ...mappedArticle.data.img && {
          image: [
            mappedArticle.data.img.src_mobile,
            mappedArticle.data.img.src_desktop,
          ],
      },
      "datePublished": mappedArticle.modified,
      "author": {
        "@type": "Person",
        "name": mappedArticle.user.data.nickName,
      },
      "publisher": {
        "@type": "Organization",
        "name": "BeSinor.NET",
      }
    };

    return `<script type="application/ld+json">
      ${JSON.stringify(dl)}
    </script>`;
  }
}
