import { IRaw, IMapped } from '@besinor/core';

export const formatStramItem = (lang: string, item: IRaw.INavigationStreamItem): IMapped.INavigationStreamItem => ({
  ...item,
  url: `/${lang}/streams/${item._id}`
});

export const formatHubItem = (lang: string, item: IRaw.INavigationHubItem): IMapped.INavigationHubItem => ({
  ...item,
  streams: item.streams.map(stream => ({ ...stream, url: `/${lang}/stream/${stream._id}` })),
  url: `/${lang}/hubs/${item._id}`
});

export const formatTagItem = (lang: string, item: IRaw.INavigationTagItem): IMapped.INavigationTagItem => ({
  ...item,
  hubs: item.hubs
    .map(hub => ({
      ...hub,
      streams: hub.streams
        .map(stream =>
          ({
            ...stream,
            url: `/${lang}/streams/${stream._id}`
          })
        ),
      url: `/${lang}/tags/${hub._id}`
      })
    ),
  url: `/${lang}/hubs/${item._id}`
});
