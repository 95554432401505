import { Injectable } from '@angular/core';
import { RestApiService } from '@besinor/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IResponse, IRaw } from '@besinor/core';
import { HOST } from '../../constants';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: RestApiService,
    private appSettings: AppSettingsSelectorsService
  ) { }

  setJwt(jwt: string): void {
    this.http.setCookie('token', jwt, undefined, '/', environment.cookieDomain);
  }

  createUser(data: any): Observable<IResponse<any>> {
    return this.http.put(`${HOST}/users/create`, data) as Observable<IResponse<any>>;
  }

  activateUser(activationKey: string): Observable<IResponse<string>> {
    return this.http.patch(`${HOST}/users/activate`, { activationKey }) as Observable<IResponse<string>>;
  }

  getMyData(): Observable<IResponse<IRaw.IUser[]>> {
    return this.http.get(`${HOST}/users/me`) as Observable<IResponse<IRaw.IUser[]>>;
  }

  getUserDataById(userId: string): Observable<IResponse<IRaw.IUser[]>> {
    return this.http.get(`${HOST}/users/id/${userId}`) as Observable<IResponse<IRaw.IUser[]>>;
  }

  authorize(credentials: { login: string, password: string }): Observable<IResponse<string>> {
    return this.http
      .post(`${HOST}/users/authorize`, credentials)
      .pipe(
        tap(({ data: jwt }) => {
          this.setJwt(jwt);
          this.appSettings.setUserAuthorizationFlag(true);
        })
      ) as Observable<IResponse<string>>
  }

  validateJwt(): Observable<IResponse<boolean>> {
    return this.http
      .get(`${HOST}/users/isValidJwt`)as Observable<IResponse<boolean>>
  }

  isLoginFree(login: string): Observable<IResponse<boolean>> {
    return this.http
      .get(`${HOST}/users/check/login/${login}`)as Observable<IResponse<boolean>>
  }

  createNewPassword(key: string, credentials: any): Observable<IResponse<string>> {
    return this.http
      .post(`${HOST}/users/create-new-password/${key}`, credentials)
      .pipe(
        tap(({ data: jwt }) => {
          this.setJwt(jwt);
          this.appSettings.setUserAuthorizationFlag(true);
        })
      ) as Observable<IResponse<string>>
  }
}
