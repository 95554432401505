import { IRaw, IMapped } from '@besinor/core';

export const convertToShortInternalArticles = (lang: string, { data, _id, hubs, user, ...meta }: IRaw.IArticle): IMapped.IArticleShort => {
  const main: IRaw.IArticleMainParts = data.find(({ type }) => type === 'HEADER') as IRaw.IArticleMainParts;

  return {
    ...meta,
    user: {
      ...user,
      url: `/${lang}/user/${user._id}`
    },
    hubs: hubs.map((hub) => ({ ...hub, streams: [], url: `/${lang}/hubs/${hub._id}`})),
    url: `/${lang}/articles/${_id}`,
    data: main
  }
};

export const convertToInternalArticles = (lang: string, { data, _id, hubs, user, ...rest }: IRaw.IArticle): IMapped.IArticle => {

  const main: IRaw.IArticleMainParts = data.find(({ type }) => type === 'HEADER') as IRaw.IArticleMainParts;

  return {
    ...rest,
    data,
    main,
    user: {
      ...user,
      url: `/${lang}/user/${user._id}`
    },
    hubs: hubs.map((hub) => ({ ...hub, streams: [], url: `/${lang}/hubs/${hub._id}`})),
    tags: []
  }
};

export const filterMalformattedItems = (articles: IRaw.IArticle[] = []): IRaw.IArticle[] =>
  articles.filter(({ data }) => data.find(({ type }) => type === 'HEADER'));

export const getArticleHeader = (article: IRaw.IArticle): IRaw.IArticleMainParts => {
  const header = article.data.find(({ type }) => type === 'HEADER') as IRaw.IArticleMainParts;

  return header;
}


export const convertToInternalComment = (lang: string, { data, _id, user, ...rest }: IRaw.IComment): IMapped.IComment => {

  return {
    ...rest,
    data,
    user: {
      ...user,
      url: `/${lang}/user/${user._id}`
    }
  }
};
