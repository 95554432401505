import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSettingsSelectorsService } from './app/app-settings-selectors.service';
import { NavSettingsSelectorsService } from './navigation/nav-settings-selectors.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AppSettingsSelectorsService,
    NavSettingsSelectorsService
  ],
})
export class StateServicesModule { }
