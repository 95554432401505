import { Component, Input } from '@angular/core';
import { IAnchorWithIcon } from '../../../interfaces';

@Component({
  selector: 'app-social-shortcuts',
  templateUrl: './social-shortcuts.component.html',
  styleUrls: ['./social-shortcuts.component.scss']
})
export class SocialShortcutsComponent {
  @Input() items: IAnchorWithIcon[] = [];
}
