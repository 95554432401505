import { Component, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval, of } from 'rxjs';
import { tap, catchError, debounce, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../../common/services/users.service';
import { SettingsService } from '../../../common/services/settings.service';

@Component({
  selector: 'app-user-registration-form',
  templateUrl: './user-registration-form.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './user-registration-form.component.scss'
  ]
})
export class UserRegistrationFormComponent implements OnDestroy {

  @Input() lang: string;

  public form: FormGroup;
  private subscriptions: Subscription[] = [];
  public submitting: Boolean = false;

  constructor(
    private user: UsersService,
    private router: Router,
    private settings: SettingsService,
    private toaster: ToastrService
  ) {
    this.form = new FormGroup({
      login: new FormControl('', [
        Validators.required,
        this.emailValidator,
      ], this.loginFree),
      nickName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ])
    });
  }

  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  loginFree = (control) => {
    if (control.value) {
      return this.user
        .isLoginFree(control.value)
        .pipe(
          debounce(() => interval(300)),
          map(({ data: isLoginOccupied }) => isLoginOccupied ? { 'invalidLogin': true } : null)
        )
    } else {
      return of(null);
    }
  }

  private validateAllFormErrors(): void {
    this.form.markAsTouched({ onlySelf: true });
  }

  public onSubmit(form: FormGroup): void {
    if (this.form.invalid) {
      this.validateAllFormErrors()
    } else {
      this.submitting = true;
      this.subscriptions.push(
        this.user
          .createUser({
            "main": {
              "login": form.value.login
            },
            "data": {
              lang: this.lang,
              ...form.value
            }
          })
          .pipe(
            tap(() => this.router.navigate([`/${this.settings.lang}/sign-up-activation`])),
            catchError(e => {
              this.submitting = false;
              this.toaster.error('ACCESS DENIED');

              return e;
            })
          )
          .subscribe()
      )
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
