import {
  Component,
  Input,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { IRaw } from '@besinor/core';

@Component({
  selector: 'besinor-article-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {

  @Input() data: IRaw.IArticleImage;
  @Input() isMobile: boolean = true;
  @Output() imageLoaded: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();
  private isVisible = false;

  constructor(private el: ElementRef) { }

  handleImageStateChange(event) {
    if (this.isVisible && this.el && event.reason === 'loading-succeeded') {
      setTimeout(() => this.imageLoaded.next(<HTMLElement>this.el.nativeElement));
    }
  }

  ngAfterContentInit() {
    this.isVisible = true;
  }
}
