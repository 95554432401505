import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { LOCALIZATION_FILE, FOOTER_ITEMS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AppStarterService {

  constructor(private settings: SettingsService) {}

  public async load(): Promise<any> {

    return await this.settings
      .setL10n(LOCALIZATION_FILE)
      .pipe(
        switchMap(() => this.settings.setTopStringsAsMainMenu(5)),
        switchMap(() => this.settings.setTopHubsAsSubMenu(this.settings.lang)),
        switchMap(() => this.settings.setGroupArticlesAsFooterItems(FOOTER_ITEMS[this.settings.lang])),
        switchMap(() => this.settings.setUserStatus()),
        catchError((_) => EMPTY)
      )
      .toPromise()
  }
}
