import { Component, Input, Output, EventEmitter } from '@angular/core';;
import { IMapped, DEFAULT_UI_LANG } from '@besinor/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {

  public userProfileLink: string;
  @Input('lang')
  set lang(lang: string) {
    this.userProfileLink = `/${lang}/profile`;
  }
  @Input() items: IMapped.INavigationStreamItem[] = [];
  @Input() mainMenuExpantionFlag: boolean = false;
  @Input() userAuthorizationFlag: boolean = false;

  @Output() mainMenuExpanderClicked: EventEmitter<void> = new EventEmitter<void>();

  public expandMenu() {
    this.mainMenuExpanderClicked.emit();
  }
}
