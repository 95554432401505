import { LANG_ENG, LANG_AR } from '@besinor/core';

export const HUBS_PER_STRESM = 9;
export const LOCALIZATION_FILE = '5e9b8d32634fe70c4c52d025';

// const LANG_ENG = 'en';
const LANG_RU = 'ru';
const LANG_KU = 'ku';
const LANG_TUR = 'tur';
// const LANG_AR = 'ar';

const LTR = 'ltr';
const RTL = 'rtl';

const LANG_LTR_SETTINGS = {
  dir: LTR
}

const LANG_RTL_SETTINGS = {
  dir: RTL
}

export const DEFAULT_UI_LANG = LANG_KU;
export const DEFAULT_UI_DIR = LTR;

export const LANG_SETTINGS = {
  [LANG_ENG]: LANG_LTR_SETTINGS,
  [LANG_RU]: LANG_LTR_SETTINGS,
  [LANG_KU]: LANG_LTR_SETTINGS,
  [LANG_AR]: LANG_RTL_SETTINGS,
  [LANG_TUR]: LANG_LTR_SETTINGS,
}

export const FOOTER_ITEMS = {
  [LANG_ENG]: 'FOOTER-EN',
  [LANG_AR]: 'FOOTER-AR',
  [LANG_KU]: 'FOOTER-KU',
}
