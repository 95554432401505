import { Component, Input } from '@angular/core';
import { IMapped } from '@besinor/core';

@Component({
  selector: 'besinor-article-short',
  templateUrl: './article-short.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './article-short.component.scss'
  ]
})
export class ArticleShortComponent {
  @Input() data: IMapped.IArticleShort;
  @Input() isMobile: boolean = true;
  @Input() votes: number = 0;
}
