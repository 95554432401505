import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { LogoLineComponent } from './logo-line/logo-line.component';
import { CreditsComponent } from './credits/credits.component';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    LogoLineComponent,
    CreditsComponent,
    AboutComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FooterComponent  ]
})
export class FooterModule { }
