<div class="top">
  <div class="border">
    <div class="container">
      <div class="quick-access">
        <div class="social">
          <app-social-shortcuts [items]="navSettings.socialLinks$ | async"></app-social-shortcuts>
        </div>
        <div class="lang">
          <app-lang-changer [items]="navSettings.langLinks$ | async"></app-lang-changer>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <app-logo
      [lang]="lang"
      [mainMenuExpantionFlag]="appSettings.mainMenuExpantionFlag$ | async"
      [userAuthorizationFlag]="appSettings.userAuthorizationFlag$ | async"
      (mainMenuExpanderClicked)="mainMenuExpanderClickHandler($event)"
    >
    </app-logo>
    <app-main-menu
      [lang]="lang"
      [items]="navSettings.mainMenuLinks$ | async | mapStreamItems:lang"
      [mainMenuExpantionFlag]="appSettings.mainMenuExpantionFlag$ | async"
      [userAuthorizationFlag]="appSettings.userAuthorizationFlag$ | async"
      (mainMenuExpanderClicked)="mainMenuExpanderClickHandler($event)"
    ></app-main-menu>
  </div>
  <app-bread-crumbs
    *ngIf="appSettings.showSubMenuFlag$ | async"
    [lang]="lang"
    [items]="navSettings.subMenuLinks$ | async | mapHubItems:lang"
  ></app-bread-crumbs>
  <app-bread-crumbs
    *ngIf="appSettings.showUserMenuFlag$ | async"
    [lang]="lang"
    [items]="navSettings.userMenuLinks$ | async"
  ></app-bread-crumbs>
</div>
