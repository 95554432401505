<div class="review" *ngIf="article && form">
  <form novalidate (ngSubmit)="onSubmit(form)" [formGroup]="form">
    <div class="review-part">
      <p>{{ SETTINGS }}</p>
      <p>LANG: {{ article.lang }}</p>
      <ul>
        <li *ngFor="let hub of article.hubs">
          {{ hub.data.text | translate:article.lang }}
        </li>
      </ul>
      <label class="review-label" [for]="SETTINGS">
        <input
          type="checkbox"
          [name]="SETTINGS"
          autocomplete="off"
          [id]="SETTINGS"
          [formControlName]="SETTINGS"
        >
        <span>OK</span>
      </label>
      <textarea
        *ngIf="!form.controls[SETTINGS].value"
        [name]="SETTINGS+'-comment'"
        [formControlName]="SETTINGS+'-comment'"
        rows="4"
      ></textarea>
    </div>
    <ng-container *ngFor="let part of (ASYNC_DATA(article.data) | async)">
      <ng-container [ngSwitch]="part.type">
        <div *ngSwitchCase="HEADER">
          <div class="review-part">
            <p>{{ part.type }}</p>
            <h3>{{ part.header }}</h3>
            <img [src]="part.img.src_tablet" *ngIf="part.img" />
            <p>{{ part.text }}</p>
            <label class="review-label" [for]="part.id">
              <input
                type="checkbox"
                [name]="part.id"
                autocomplete="off"
                [id]="part.id"
                [formControlName]="part.id"
              >
              <span>OK</span>
            </label>
            <textarea
              *ngIf="!form.controls[part.id].value"
              [name]="part.id+'-comment'"
              [formControlName]="part.id+'-comment'"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="review-part" *ngSwitchCase="PARAGRAPH">
          <p>{{ part.type }}</p>
          <p [innerHtml]="part.text | safe: 'html'"></p>
          <label class="review-label" [for]="part.id">
            <input
              type="checkbox"
              name="policy"
              autocomplete="off"
              [id]="part.id"
              [formControlName]="part.id"
            >
            <span>OK</span>
          </label>
          <textarea
            *ngIf="!form.controls[part.id].value"
            [name]="part.id+'-comment'"
            [formControlName]="part.id+'-comment'"
            rows="4"
          ></textarea>
        </div>
        <div class="review-part" *ngSwitchCase="CITE">
          <p>{{ part.type }}</p>
          <p>{{ part.quote }}</p>
          <label class="review-label" [for]="part.id">
            <input
              type="checkbox"
              name="policy"
              autocomplete="off"
              [id]="part.id"
              [formControlName]="part.id"
            >
            <span>OK</span>
          </label>
          <textarea
            *ngIf="!form.controls[part.id].value"
            [name]="part.id+'-comment'"
            [formControlName]="part.id+'-comment'"
            rows="4"
          ></textarea>
        </div>
        <div class="review-part" *ngSwitchCase="IMAGE">
          <p>{{ part.type }}</p>
          <img [src]="part.img.src_tablet" />
          <label class="review-label" [for]="part.id">
            <input
              type="checkbox"
              name="policy"
              autocomplete="off"
              [id]="part.id"
              [formControlName]="part.id"
            >
            <span>OK</span>
          </label>
          <textarea
            *ngIf="!form.controls[part.id].value"
            [name]="part.id+'-comment'"
            [formControlName]="part.id+'-comment'"
            rows="4"
          ></textarea>
        </div>
      </ng-container>
    </ng-container>
    <div class="review-submit button">
      <input
        type="submit"
        [value]="(submitting ? 'working' : 'submitReview') | translate:lang"
        class="button-submit"
        [disabled]="form.invalid || submitting"
        [ngClass]="{'disabled': form.invalid || submitting}"
      >
    </div>
  </form>
</div>
