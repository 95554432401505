import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IRaw } from '@besinor/core';

@Component({
  selector: 'besinor-article-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: [
    '../../../sharedStyles.scss',
    './paragraph.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ParagraphComponent {

  @Input() data: IRaw.IArticleParagraph;
}
