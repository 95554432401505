import { createReducer, on } from '@ngrx/store';
import { IVanigationItems } from '../../../interfaces';
import * as actions from './actions';
import { navInitialState } from './model';

const onMainMenuChange = (state: IVanigationItems, { data: mainMenuLinks }) => ({
  ...state,
  mainMenuLinks
});

const onSubMenuChange = (state: IVanigationItems, { data: subMenuLinks }) => ({
  ...state,
  subMenuLinks
});

const onSocialLinksChange = (state: IVanigationItems, { data: socialLinks }) => ({
  ...state,
  socialLinks
});

const onLangSelectorChange = (state: IVanigationItems, { data: langLinks }) => ({
  ...state,
  langLinks
});

const onFooterLinksChange = (state: IVanigationItems, { data: footerLinks }) => ({
  ...state,
  footerLinks
});

const onUserMenuLinksChange = (state: IVanigationItems, { data: userMenuLinks }) => ({
  ...state,
  userMenuLinks
});

export const navigationReducer = createReducer(
  navInitialState,
  on(actions.setSocialLinks, onSocialLinksChange),
  on(actions.setLangLinks, onLangSelectorChange),
  on(actions.setMainMenu, onMainMenuChange),
  on(actions.setSubMenu, onSubMenuChange),
  on(actions.setFooterLinks, onFooterLinksChange),
  on(actions.setUserMenuLinks, onUserMenuLinksChange)
);
