import { Component, Input } from '@angular/core';
import { CP_HOST } from '../../../constants';

@Component({
  selector: 'app-write-article-link',
  templateUrl: './write-article-link.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './write-article-link.component.scss'
  ]
})
export class WriteArticleLinkComponent {
  @Input() lang: string;
  public controlPanelUr = CP_HOST + '/articles';
}
