import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../../common/services/users.service';
import { CP_HOST } from 'src/app/constants';

@Component({
  selector: 'app-user-login-form',
  templateUrl: './user-login-form.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './user-login-form.component.scss'
]
})
export class UserLoginFormComponent implements OnDestroy {

  @Input() lang: string;

  public form: FormGroup;
  public submitting: Boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private user: UsersService,
    private router: Router,
    private toaster: ToastrService
  ) {
    this.form = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(2)])
    });

  }

  public onSubmit(form: FormGroup): void {
    this.submitting = true;

    this.subscriptions.push(
      this.user
        .authorize({
          "login": form.value.login,
          "password": form.value.password
        })
        .pipe(
          // tap(() => this.router.navigate([`/${this.lang}/profile`])),
          tap(() => window.location.href = CP_HOST),
          catchError(e => {
            this.submitting = false;
            this.toaster.error('ACCESS DENIED');

            return e;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
