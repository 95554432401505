import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { appReducer } from './common/state/app/reducer';
import { navigationReducer } from './common/state/navigation/reducer';
import { AppRoutingModule } from './router/app-routing.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
import { AppStarterService } from './common/services/app-starter.service';
import { ServicesModule } from './common/services/services.module';
import { StateServicesModule } from '../app/common/state/state-services.module';
import { AppSettingsSideEffectsService } from '../app/common/state/app/app-settings-side-effects.service';
import { GuardsModule } from './common/guards/guards.module';
import { PipesModule } from './common/pipes/pipes.module';
import { CommonComponentsModule } from './components/common/common-components.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'besinorSSR' }),
    StoreModule.forRoot({
      app: appReducer,
      navigation: navigationReducer
    }),
    EffectsModule.forRoot([
      AppSettingsSideEffectsService
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false
    }),
    AppRoutingModule,
    CommonComponentsModule,
    StateServicesModule,
    ServicesModule,
    LayoutsModule,
    PipesModule,
    GuardsModule,
    NoopAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    })
  ],
  providers: [
    AppStarterService,
    {
      provide: APP_INITIALIZER,
      useFactory: (startupService: AppStarterService): Function => {
        return () => startupService.load();
      },
      deps: [
        AppStarterService
      ],
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
