import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { DonationSelectorComponent } from './donation-selector/donation-selector.component';

@NgModule({
  declarations: [
    DonationSelectorComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule
  ],
  exports: [
    DonationSelectorComponent
  ]
})
export class DonateModule { }
