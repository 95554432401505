import { IVanigationItems } from '../../../interfaces';

export const navInitialState: IVanigationItems = {
  socialLinks: [],
  langLinks: [],
  mainMenuLinks: [],
  subMenuLinks: [],
  footerLinks: [],
  userMenuLinks: []
}
