<div class="container authorization">
  <div class="col">
    <besinor-article
      *ngIf="articles"
      [data]="articles | normalizeArticles | firstItemInArray"
      [noHeader]="true"
    ></besinor-article>
  </div>
  <div class="col form">
    <app-user-registration-form [lang]="appSettings.lang$ | async"></app-user-registration-form>
  </div>
</div>
