import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { tap, take, catchError} from 'rxjs/operators';
import { ARTICLE, IRaw } from '@besinor/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ArticlesService } from '../../../../common/services/articles.service';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: [
    '../../../../components/sharedStyles.scss',
    './review-form.component.scss'
  ]
})
export class ReviewFormComponent implements OnChanges {

  @Input() article: IRaw.IArticle;
  @Input() lang: string;
  public form: FormGroup = new FormGroup({});
  public submitting: boolean;
  public ASYNC_DATA = (data) => of(data);
  public HEADER = ARTICLE.HEADER;
  public PARAGRAPH = ARTICLE.PARAGRAPH;
  public IMAGE = ARTICLE.IMAGE;
  public CITE = ARTICLE.CITE;
  public SLIDER = ARTICLE.SLIDER;
  public SETTINGS = ARTICLE.SETTINGS;

  private subscriptions: Subscription[] = [];

  constructor(private articlesService: ArticlesService, private toaster: ToastrService) { }

  public onSubmit(form: FormGroup): void {
    this.submitting = true;
    this.subscriptions.push(
      this.articlesService
        .addArticleReview(this.article._id, form.value)
        .pipe(
          tap(() => {
            this.submitting = false;
            this.toaster.success('OK');
          }),
          take(1),
          catchError(e => {
            this.submitting = false;
            this.toaster.error('SERVER ERROR');

            return e;
          })
        )
        .subscribe()
    );
  }

  private isCommentRequired(fieldId: string): ValidatorFn {
    const commentFieldId = `${fieldId}-comment`;
    return (control: AbstractControl): ValidationErrors => {
      if (this.form.controls[commentFieldId]) {
        const commentFieldController = this.form.controls[commentFieldId];
        if (!control.value) {
          commentFieldController.setValidators([Validators.required]);
          commentFieldController.updateValueAndValidity();
        } else {
          commentFieldController.setValidators([]);
          commentFieldController.updateValueAndValidity();
        }
      }

      return null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const prev = changes['article'] && changes['article'].previousValue;
    const next = changes['article'] && changes['article'].currentValue;

    if (prev !== next && next) {
      const { data: articlParts } = this.article;
      const articlePartsFormControls = {};
      articlePartsFormControls[this.SETTINGS] = new FormControl("", [this.isCommentRequired(this.SETTINGS)]);
      articlePartsFormControls[`${this.SETTINGS}-comment`] = new FormControl("", []);

      articlParts.forEach(({ id }) => {
        articlePartsFormControls[id] = new FormControl("", [this.isCommentRequired(id)]);
        articlePartsFormControls[`${id}-comment`] = new FormControl("", []);
      });

      this.form = new FormGroup(articlePartsFormControls);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
