import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap, map, catchError, switchMap, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRaw } from '@besinor/core';
import { ToastrService } from 'ngx-toastr';
import { ArticlesService } from '../../common/services/articles.service';
import { UsersService } from '../../common/services/users.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { SIGN_UP_ACTIVATION_PAGE_TIPS } from './constants';
import { CP_HOST } from 'src/app/constants';

@Component({
  selector: 'app-sign-up-activation',
  templateUrl: './sign-up-activation.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    '../../components/sharedStyles.scss', // this is taken from common styles
    './sign-up-activation.component.scss'
  ]
})
export class SignUpActivationComponent implements OnInit, OnDestroy {

  public articles: IRaw.IArticle[];
  public form: FormGroup;
  public submitting: Boolean = false;
  public lang: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private articlesServices: ArticlesService,
    private user: UsersService,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {
    this.form = new FormGroup({
      activationKey: new FormControl('', [Validators.required, Validators.minLength(2)])
    });
  }

  public onSubmit(form: FormGroup): void {
    this.submitting = true;

    this.subscriptions.push(
      this.user
        .activateUser(form.value.activationKey)
        .pipe(
          tap(({ data: jwt }) => this.user.setJwt(jwt)),
          // tap(() => this.router.navigate(['/'])),
          tap(() => {
            this.toaster.success('OK');
            window.location.href = CP_HOST;
          }),
          catchError(e => {
            this.submitting = false;
            this.toaster.error('INVALID ACTIVATION KEY', 'Or your account might have been activated already');

            return e;
          })
        )
        .subscribe()
    )
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.appSettings
        .lang$
        .pipe(
          tap((lang) => {
            this.lang = lang;
            this.seo.setSeoMetaData(
              this.translate.transform('seoActivationTitle', lang),
              this.translate.transform('seoActivationDesciption', lang)
            );
          }),
          switchMap(lang => this.articlesServices.listGroupById(SIGN_UP_ACTIVATION_PAGE_TIPS[lang])),
          map(({ data: [ group ] }) => group.articles),
          tap((articles) => this.articles = articles)
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.route.paramMap
        .pipe(
          map((params: ParamMap) => params.get('key')),
          filter(Boolean),
          tap((activationKey) => this.form.patchValue({ activationKey })),
        )
        .subscribe()
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
