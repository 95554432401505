import { IMapped } from '@besinor/core';

const myArticles = (lang): IMapped.INavigationHubItem => ({
  _id: undefined,
  created: undefined,
  modified: undefined,
  status: undefined,
  streams: undefined,
  data: {
    text: 'articles',
    description: undefined
  },
  url: `/${lang}/profile/articles`
});

const myComments = (lang): IMapped.INavigationHubItem => ({
  _id: undefined,
  created: undefined,
  modified: undefined,
  status: undefined,
  streams: undefined,
  data: {
    text: 'comments',
    description: undefined
  },
  url: `/${lang}/profile/comments`
});

const myAwards = (lang): IMapped.INavigationHubItem => ({
  _id: undefined,
  created: undefined,
  modified: undefined,
  status: undefined,
  streams: undefined,
  data: {
    text: 'awards',
    description: undefined
  },
  url: `/${lang}/profile/awards`
});

const exit = (lang): IMapped.INavigationHubItem => ({
  _id: undefined,
  created: undefined,
  modified: undefined,
  status: undefined,
  streams: undefined,
  data: {
    text: 'exit',
    description: undefined
  },
  url: `/${lang}/profile/exit`
});

export const profileMenu = (lang): IMapped.INavigationHubItem[] => [
  myArticles(lang),
  myComments(lang),
  myAwards(lang),
  exit(lang)
];
