export const profileDataKeys: string[] = [
  'userFullName',
  'userEducation',
  'userArticles',
  'userVotes',
];

export const profileSocialKeys: string[] = [
  'userFacebook',
  'userTwitter',
]
