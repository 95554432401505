import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType,  } from '@ngrx/effects';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { doNothingMore } from '../common/actions';
import { AppSettingsSelectorsService } from './app-settings-selectors.service';
import { SettingsService } from '../../services/settings.service';
import { NavSettingsSelectorsService } from '../navigation/nav-settings-selectors.service';
import { FOOTER_ITEMS } from '../../constants';
import { SOCIAL_MAP } from '../../../constants';
import * as action from './actions';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsSideEffectsService {

  constructor(
    private actions: Actions,
    private appSettings: AppSettingsSelectorsService,
    @Inject(DOCUMENT) private document: Document,
    private settingsService: SettingsService,
    private navService: NavSettingsSelectorsService,
  ) { }

  
  setDocumentAttributes: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(action.setLang),
    switchMap(() => combineLatest([
      this.appSettings.lang$,
      this.appSettings.dir$
    ])),
    tap(([lang, dir]) => {
      this.document.documentElement.setAttribute('dir', dir);
      this.document.documentElement.setAttribute('lang', lang);
      this.navService.setSocialLinks(SOCIAL_MAP[lang]);
    }),
    map(() => ({ type: doNothingMore.type }))
  ));

  
  setAppLang: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(action.setLang),
    switchMap(({ data: lang }) =>
      this.settingsService.setGroupArticlesAsFooterItems(FOOTER_ITEMS[lang])
    ),
    map(() => ({ type: doNothingMore.type }))
  ));


}
