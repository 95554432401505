import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../../../common/services/users.service';
import { SettingsService } from '../../../common/services/settings.service';
import { AppSettingsSelectorsService } from '../../../common/state/app/app-settings-selectors.service';

@Component({
  selector: 'app-profile-exit',
  templateUrl: './profile-exit.component.html',
  styleUrls: ['./profile-exit.component.scss']
})
export class ProfileExitComponent implements OnInit {

  constructor(
    private router: Router,
    private user: UsersService,
    private settings: SettingsService,
    private appSettings: AppSettingsSelectorsService
  ) { }

  ngOnInit(): void {
    this.user.setJwt(undefined);
    this.appSettings.setUserAuthorizationFlag(false);
    this.router.navigate([`/${this.settings.lang}/landing`]);
  }

}
