import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '../../common/common-components.module';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { CommentAddFormComponent } from './comment-add-form/comment-add-form.component';
import { CommentsListComponent } from './comments-list/comments-list.component';

@NgModule({
  declarations: [
    CommentAddFormComponent,
    CommentsListComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonComponentsModule
  ],
  exports: [
    CommentAddFormComponent,
    CommentsListComponent
  ]
})
export class CommentsModule { }
