import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { LangDirPipe } from './lang-dir.pipe';
import { SafePipe } from './safe.pipe';
import { MapNavHubItemsPipe, MapNavStreamItemsPipe } from './map-nav-items.pipe';
import {
  MapArticleItemsPipe,
  MapArticleItemsToShortPipe,
  ReturnFirstArticlePipe,
  ToStructuredData
} from './map-article-items.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    LangDirPipe,
    SafePipe,
    MapNavHubItemsPipe,
    MapNavStreamItemsPipe,
    MapArticleItemsPipe,
    MapArticleItemsToShortPipe,
    ReturnFirstArticlePipe,
    ToStructuredData
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslatePipe,
    LangDirPipe,
    SafePipe,
    MapNavHubItemsPipe,
    MapNavStreamItemsPipe,
    MapArticleItemsPipe,
    MapArticleItemsToShortPipe,
    ReturnFirstArticlePipe,
    ToStructuredData
  ],
  providers: [
    TranslatePipe
  ]
})
export class PipesModule { }
