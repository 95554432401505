import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { SettingsService } from '../services/settings.service';
import { throwError, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorizedGuard implements CanActivate, CanActivateChild {
  constructor(private settings: SettingsService, public router: Router) { }

  canActivate(): Observable<boolean> {

    return this.settings.setUserStatus()
      .pipe(
        tap(isAuthorized => {
          if (!isAuthorized) {
            this.router.navigate([`/${this.settings.lang}/sign-in`]);
          }
        }),
        catchError((e) => {
          this.router.navigate([`/${this.settings.lang}/sign-in`]);

          return throwError(e);
        })
      ) as Observable<boolean>;
  }

  canActivateChild(): Observable<boolean> {

    return this.canActivate();
  }

}
