import { environment } from '../environments/environment'
import { IAnchorWithIcon } from './interfaces';

export const HOST = environment.apiUrl;

export const CP_HOST = environment.cpUrl;

export const PAYMENT_HOST = 'https://payment.besinor.net';
// export const PAYMENT_HOST = '//localhost:4210';

export const BESINOR_DOMAIN = 'https://demo.besinor.net';
export const STRIPE_KEY = 'pk_test_Fn7oOa8Qm28PXOAduadCAecs00ZC2j7QJc';

export const MOBILE_EDGE = 1023;
export const DESKTOP_NARROW_EDGE = 1279;
export const ESC = 27;

export const socialItemsKu: IAnchorWithIcon[] = [
  // {
  //   url: 'http://twitter.com/BesinorNet',
  //   text: 'BeSinor Twitter',
  //   icon: 'fa-twitter'
  // },
  {
    url: 'http://t.me/wwwbesinornet',
    text: 'BeSinor Telegram',
    icon: 'fa-telegram'
  },
  {
    url: 'https://www.facebook.com/groups/770997343593077',
    text: 'BeSinor Facebook',
    icon: 'fa-facebook-f'
  }
];

export const socialItemsAr: IAnchorWithIcon[] = [
  // {
  //   url: 'http://twitter.com/BesinorNet',
  //   text: 'BeSinor Twitter',
  //   icon: 'fa-twitter'
  // },
  {
    url: 'http://t.me/besinorNetAr',
    text: 'BeSinor Telegram',
    icon: 'fa-telegram'
  },
  {
    url: 'https://www.facebook.com/groups/2154410391379371',
    text: 'BeSinor Facebook',
    icon: 'fa-facebook-f'
  }
];


export const ANALYTICS_GOOGLE_KEY = 'UA-168770600-1';

import { LANG_ENG, LANG_AR, LANG_KU } from '@besinor/core';

export const SIGN_UP_ACTIVATION_PAGE_TIPS = {
  [LANG_ENG]: 'SIGN_UP_ACTIVATION_PAGE-EN',
  [LANG_AR]: 'SIGN_UP_ACTIVATION_PAGE-AR',
  [LANG_KU]: 'SIGN_UP_ACTIVATION_PAGE-KU',
}

export const SOCIAL_MAP = {
  [LANG_ENG]: socialItemsKu,
  [LANG_AR]: socialItemsAr,
  [LANG_KU]: socialItemsKu,
}
