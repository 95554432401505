import { Component, ElementRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IRaw } from '@besinor/core';

@Component({
  selector: 'besinor-article-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  @Input() data: IRaw.IArticleSlider;
  @Input() isMobile: boolean = true;
  public data_: IRaw.IArticleSlider;
  public carouselHeight: number = this.isMobile ? 191.5 : 343.8;
  public isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
}
  handleImageLoaded(elemen: HTMLElement) {
    const el = elemen.querySelector('div');
    const h = Math.round(el.offsetWidth / 1.78);
    this.carouselHeight = Math.max(this.carouselHeight, h);
  }
}
