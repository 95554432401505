import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonComponentsModule } from '../../../common/common-components.module';
import { LazyLoadImageHooks }  from './lazyLoadImagesHooks';
import { PipesModule } from '../../../../common/pipes/pipes.module';
import { ArticleComponent } from './article.component';
import { SliderComponent } from './components/slider/slider.component';
import { ImageComponent } from './components/image/image.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { CiteComponent } from './components/cite/cite.component';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
  declarations: [
    ArticleComponent,
    SliderComponent,
    ImageComponent,
    ParagraphComponent,
    CiteComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    LazyLoadImageModule.forRoot(LazyLoadImageHooks),
    IvyCarouselModule,
    RouterModule,
    CommonComponentsModule
  ],
  exports: [
    ArticleComponent,
    HeaderComponent
  ]
})
export class ArticleModule { }
