import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { IMapped, IRaw } from '@besinor/core';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { formatUserData, filterMissingFields } from '../../common/helpers/users';
import { ArticlesService } from '../../common/services/articles.service';
import { UsersService } from '../../common/services/users.service';
import { IUserProfile } from 'src/app/components/content/user-profile/interfaces';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './user-page.component.scss'
  ]
})
export class UserPageComponent implements OnInit, OnDestroy {

  public userDetailsMenu: any[] = [];
  public items: IRaw.IArticle[] = [];
  public userData: IMapped.IUserProfile;
  public lang: string;
  public isLoading: boolean = false;
  public haveMore: boolean = true;

  private subscriptions: Subscription[] = [];
  private page$: BehaviorSubject<number> = new BehaviorSubject(0);
  private currentPage: number = 0;
  private userId: string;

  constructor(
    private articles: ArticlesService,
    private route: ActivatedRoute,
    private users: UsersService,
    public appSettings: AppSettingsSelectorsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) { }

  onNextPage() {
    this.currentPage = this.currentPage + 1;
    this.page$.next(this.currentPage);
  }

  private resetPagination() {
    this.items = [];
    this.haveMore = true;
    this.currentPage = 0;
    this.page$.next(this.currentPage);
  }

  trackListChangingEvents() {
    this.subscriptions
      .push(
        combineLatest([
          this.route.paramMap
            .pipe(
              map((params: ParamMap) => params.get('userId')),
              tap((userId) => {
                this.items = [];
                this.userId = userId;
                this.resetPagination();
              })
            ),
          this.page$
        ])
        .pipe(
          tap(() => this.isLoading = true),
          switchMap(([userId, page]) => this.articles.listUserArticles(userId, page)),
          tap(({ data: newItems = [] }) => {
            this.isLoading = false;
            if (newItems.length === 0) {
              this.haveMore = false;
            } else {
              this.items = [
                ...this.items,
                ...newItems
              ];
            }
          })
        )
        .subscribe()
      )
  }

  trackUserDataChanges() {
    this.subscriptions.push(
      this.route.paramMap
      .pipe(
        map((params: ParamMap) => params.get('userId')),
        switchMap((userId) => this.users.getUserDataById(userId)),
        map(({ data: [user] }): IUserProfile => filterMissingFields(formatUserData(user))),
        tap((userData) => {
          this.userData = userData;
          this.seo.setSeoMetaData(
            `${userData.nickName.text} - BESINOR`,
            `${this.translate.transform('seoUserPageDesciption')} - ${userData.nickName.text}`
          );
        }),
      )
      .subscribe()
    )
  }

  ngOnInit() {
    this.trackListChangingEvents();
    this.trackUserDataChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
