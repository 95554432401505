import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { SettingsService } from '../common/services/settings.service';
import { AppSettingsSelectorsService } from '../common/state/app/app-settings-selectors.service';
import { NavSettingsSelectorsService } from '../common/state/navigation/nav-settings-selectors.service';

@Component({
  selector: 'app-layouts-wrapper',
  templateUrl: './layouts-wrapper.component.html',
  styleUrls: ['./layouts-wrapper.component.scss']
})
export class LayoutsWrapperComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  public lang: string;

  constructor(
    private route: ActivatedRoute,
    private appSettings: AppSettingsSelectorsService,
    public navSettings: NavSettingsSelectorsService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.route
        .paramMap
        .pipe(
          map((params: ParamMap) => params.get('lang')),
          tap((lang) => {
            this.appSettings.setLang(lang);
            this.lang = lang;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
