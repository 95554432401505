import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { MOBILE_EDGE } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {

  public isMobile: Subject<boolean> = new Subject<boolean>();
  public isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isMobile.next(this.isDeviceMobile());
  }

  public resizeHandler() {
    this.isMobile.next(this.isDeviceMobile());
  }

  private isDeviceMobile() {
    return this.isBrowser ? window.innerWidth <= MOBILE_EDGE : false;
  }

}
