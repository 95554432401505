import { Component, Input } from '@angular/core';
import { DEFAULT_UI_LANG } from '@besinor/core';
import { IUserProfile } from './interfaces';
import { profileDataKeys, profileSocialKeys } from './constants';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './user-profile.component.scss'
  ]
})
export class UserProfileComponent {
  @Input() header: string;
  @Input() data: IUserProfile;
  @Input() profilerKeys: string[] = profileDataKeys;
  @Input() socialKeys: string[] = profileSocialKeys;
  @Input() lang: string = DEFAULT_UI_LANG;
}
