<div class="container" appLangDirection>
  <div class="sidebar">
    <app-user-profile [data]="userData" [lang]="appSettings.lang$ | async"></app-user-profile>
    <div class="sideComponent">
      <app-side-share
        [header]="'shareThisPage' | translate:lang"
      ></app-side-share>
    </div>
  </div>
  <div class="articles">
    <app-articles-list
      [items]="items | normalizeShortArticles"
      [isMobile]="appSettings.mobileDeviceFlag$ | async"
    ></app-articles-list>
    <div class="load-more">
      <app-load-more-results
        [loadMoreText]="'loadMoreArticles'"
        (nextPage)="onNextPage()"
        [isLoading]="isLoading"
        [haveMore]="haveMore"
      ></app-load-more-results>
    </div>
  </div>
</div>
