import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent {

  public isBrowser: boolean = false;

  public allowedCarriers: string[] = [
    'facebook',
    'telegram',
    'whatsapp',
    'sms',
    'messenger',
    'viber',
    'twitter',
    'linkedin',
    'reddit',
    'vk',
    'email',
    'print',
    'copy'
  ];
  public showFirst: number = 3;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

}
