import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { IRaw } from '@besinor/core';
import { SettingsService } from '../../common/services/settings.service';
import { ArticlesService } from '../../common/services/articles.service';
import { SeoService } from '../../common/services/seo.service';
import { TranslatePipe } from '../../common/pipes/translate.pipe';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: [
    '../sharedStyles.scss',
    './landing-page.component.scss'
  ]
})
export class LandingPageComponent implements OnInit, OnDestroy {

  public items: IRaw.IArticle[];
  public topItems: IRaw.IArticle[];
  public lang: string;

  private subscriptions: Subscription[] = [];

  private page$: BehaviorSubject<number> = new BehaviorSubject(0);
  private currentPage: number = 0;
  public isLoading: boolean = false;
  public haveMore: boolean = true;

  constructor(
    private articles: ArticlesService,
    public appSettings: AppSettingsSelectorsService,
    public settingsService: SettingsService,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {
  }

  onNextPage() {
    this.currentPage = this.currentPage + 1;
    this.page$.next(this.currentPage);
  }

  private resetPagination() {
    this.items = [];
    this.haveMore = true;
    this.currentPage = 0;
    this.page$.next(this.currentPage);
  }

  trackListChangingEvents() {
    this.subscriptions
      .push(
        combineLatest([
          this.appSettings.lang$
            .pipe(
              tap((lang) => {
                this.lang = lang;
                this.resetPagination();
              })
            ),
          this.page$
        ])
        .pipe(
          tap(() => this.isLoading = true),
          switchMap(([lang, page]) => this.articles.listArticlesByPage(lang, page)),
          tap(({ data: newItems = [] }) => {
            this.isLoading = false;
            if (newItems.length === 0) {
              this.haveMore = false;
            } else {
              this.items = [
                ...this.items,
                ...newItems
              ];
            }
          })
        )
        .subscribe()
      );
  }

  trackSeoChanges() {
    this.subscriptions.push(
      this.appSettings.lang$
      .pipe(
        tap((lang) => {
          this.seo.setSeoMetaData(
            this.translate.transform('seoLandingTitle', lang),
            this.translate.transform('seoLandingDesciption', lang)
          );
        }),
      )
      .subscribe()
    )
  }

  trackTopHubs() {
    this.subscriptions.push(
      this.appSettings.lang$
        .pipe(
          switchMap((lang) => this.settingsService.setTopHubsAsSubMenu(lang))
        )
        .subscribe()
    )
  }

  trackSectionTopItems() {
    this.subscriptions.push(
      this.appSettings.lang$
        .pipe(
          switchMap((lang) => this.articles.listArticlesByPage(lang, 0, 'popular')),
          map(({ data: articles }) => articles),
          tap((items) => this.topItems = items)
        )
        .subscribe()
      )
  }

  ngOnInit() {
    this.trackListChangingEvents();
    this.trackSeoChanges();
    this.trackSectionTopItems();
    this.trackTopHubs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
