import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IRaw } from '@besinor/core';
import { ArticlesService } from '../../../../common/services/articles.service';
import { InvoicesService } from '../../../../common/services/invoices.service';
import { AppSettingsSelectorsService } from '../../../../common/state/app/app-settings-selectors.service';

@Component({
  selector: 'app-amount-payment-confirmation-page',
  templateUrl: './amount-payment-confirmation-page.component.html',
  styleUrls: ['./amount-payment-confirmation-page.component.scss']
})
export class AmountPaymentConfirmationPageComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  private reciepeId: string;
  public reciepe: IRaw.IPaymentTransaction;
  public isLoading: boolean = false;
  public noReciepe: boolean = false;
  public lang: string;

  constructor(
    private route: ActivatedRoute,
    private articlesService: ArticlesService,
    public settings: AppSettingsSelectorsService,
    private invoiceService: InvoicesService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap
        .pipe(
          tap(() => this.isLoading = true),
          map((params: ParamMap) => params.get('reciepeId')),
          tap((reciepeId) => this.reciepeId = reciepeId),
          switchMap((reciepeId) => this.invoiceService.getReciepeById(reciepeId)),
          tap(({ data: reciepe }) => {
            this.reciepe = reciepe;
            this.isLoading = false;
          }),
          catchError((e) => {
            this.noReciepe = true;
            this.isLoading = false;
            return e;
          })
        )
        .subscribe()
    );

    this.subscriptions
      .push(
        this.settings.lang$.subscribe(lang => this.lang = lang)
      );
  }

}
