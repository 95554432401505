<div class="container" appLangDirection>
  <div class="articles">
    <besinor-article
      [data]="articles | normalizeArticles | firstItemInArray"
      [isMobile]="settings.mobileDeviceFlag$ | async"
    ></besinor-article>
    <app-article-structured-data
      *ngIf="articles | firstItemInArray"
      [article]="articles | firstItemInArray"
    ></app-article-structured-data>
    <!-- <div class="controllers">
      <h4 class="controller-header">{{ 'articleControllers' | translate:lang }}</h4>
      <app-article-controllers [articleId]="articleId"></app-article-controllers>
    </div> -->
    <!-- <div class="donate" *ngIf="isDonationAllowed">
      <router-outlet></router-outlet>
    </div> -->
    <!-- <div class="comments" *ngIf="isDiscussionAllowed">
      <app-comment-add-form
        *ngIf="settings.userAuthorizationFlag$ | async"
        [articleId]="articleId"
      >
      </app-comment-add-form>
      <div *ngIf="!(settings.userAuthorizationFlag$ | async)">
        <input
          type="button"
          [routerLink]="'/' + lang + '/sign-in'"
          [value]="'loginToWriteAComment' | translate:lang"
          class="button-submit"
      >
      </div>
      <app-comments-list
        [articleId]="articleId"
        [lang]="lang"
      ></app-comments-list>
    </div> -->
  </div>
  <aside class="sidebar">
    <app-user-profile
      [data]="userData"
    >
    </app-user-profile>
    <div class="sideComponent">

    </div>
    <app-side-links
      [lang]="lang"
      [isLogedIn]="settings.userAuthorizationFlag$ | async"
    ></app-side-links>
    <app-side-bar
      [header]="'similarArticles' | translate:lang"
      [items]="topArticles | normalizeShortArticles"
    ></app-side-bar>
  </aside>
</div>
