import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DEFAULT_UI_LANG } from '@besinor/core';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { NavSettingsSelectorsService } from '../../common/state/navigation/nav-settings-selectors.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  @Input() lang: string = DEFAULT_UI_LANG;

  public langLinks = [];
  public navMenu = [];
  public breadCrumbs = [];
  public mainMenuExpantionFlag: boolean = false;
  public userAuthorizationFlag: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public appSettings: AppSettingsSelectorsService,
    public navSettings: NavSettingsSelectorsService
  ) { }

  public mainMenuExpanderClickHandler(e) {
    this.appSettings.setMainMenuExpantionFlag();
  }

  ngOnInit() {
    this.subscriptions
      .push(
        this.appSettings
          .mainMenuExpantionFlag$
          .pipe(
            tap(flag => this.mainMenuExpantionFlag = flag)
          )
          .subscribe()
      );

    this.subscriptions
      .push(
        this.appSettings
          .userAuthorizationFlag$
          .pipe(
            tap(flag => this.userAuthorizationFlag = flag)
          )
          .subscribe()
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
