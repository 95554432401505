import { Component, OnInit, HostListener } from '@angular/core';
import { Subscription, combineLatest, Subject, interval } from 'rxjs';
import { tap, map, switchMap, debounce } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IRaw, IMapped } from '@besinor/core';
import { SeoService } from '../../common/services/seo.service';
import { AppSettingsSelectorsService } from '../../common/state/app/app-settings-selectors.service';
import { getArticleHeader } from '../../common/helpers/articles';
import { ArticlesService } from '../../common/services/articles.service';

@Component({
  selector: 'app-article-preview-page',
  templateUrl: './article-preview-page.component.html',
  styleUrls: [
    '../../components/sharedStyles.scss',
    '../sharedStyles.scss',
    './article-preview-page.component.scss'
  ]
})
export class ArticlePreviewPageComponent implements OnInit {

  @HostListener('window:visibilitychange')
  onActiveTab() {
    this.refresh$.next();
  }

  @HostListener('window:message', ['$event'])
  onMessage(even) {
    // this.refresh$.next();
  }

  public articles: IRaw.IArticle[] = [];
  public topArticles: IMapped.IArticleShort[] = [];
  public lang: string;
  public isCreatingInvoice: boolean = false;
  private subscriptions: Subscription[] = [];
  private refresh$: Subject<void> = new Subject<void>();

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    public settings: AppSettingsSelectorsService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.route.paramMap.pipe(map((params: ParamMap) => params.get('articleId'))),
        this.refresh$
      ])
        .pipe(
          debounce(() => interval(500)),
          switchMap(([articleId, _]) => this.articlesService.articleByIdForPreview(articleId)),
          map(({ data: articles }) => articles),
          tap((articles) => {
            this.articles = articles;
            this.seo.setPageSeoFromArticle(getArticleHeader(articles[0]));
          })
        )
        .subscribe()
    );

    this.subscriptions
      .push(this.settings.lang$.subscribe(lang => this.lang = lang));

    this.refresh$.next();
  }

  ngOnDestroy() {
    this.subscriptions
      .forEach(sub => sub.unsubscribe())
  }
}
