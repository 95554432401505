import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IRaw, IMapped } from '@besinor/core';
import { of } from 'rxjs';

@Component({
  selector: 'besinor-article-header',
  templateUrl: './header.component.html',
  styleUrls: [
    '../../../sharedStyles.scss',
    './header.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input() data: IRaw.IArticleMainParts;
  @Input() modified: string;
  @Input() hubs: IMapped.INavigationHubItem[] = [];
  @Input() user: IMapped.IUser;
  @Input() skipOverview: boolean = false;
  @Input() lang: string;
  @Input() isMobile: boolean = true;
  @Input() votes: number = 0;
  @Input() hidden: boolean = false;

  public ASYNC_DATA = (data) => of(data);
}
