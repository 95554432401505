<div class="container" appLangDirection>
  <div class="articles">
    <app-articles-list
      [items]="articles | normalizeShortArticles"
      [isMobile]="settings.mobileDeviceFlag$ | async"
    ></app-articles-list>
    <besinor-article
      [data]="articles | normalizeArticles | firstItemInArray"
      [isMobile]="settings.mobileDeviceFlag$ | async"
    ></besinor-article>
  </div>
  <div class="sidebar">
    <div class="sideComponent">
      <app-side-bar
        [header]="'similarArticles' | translate:lang"
        [items]="articles | normalizeShortArticles"
      ></app-side-bar>
    </div>
  </div>
</div>
