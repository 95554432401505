import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../common/pipes/pipes.module';
import { CommonComponentsModule } from '../../components/common/common-components.module';
import { ContentModule } from '../../components/content/content.module';
import { ProfileLandingComponent } from './profile-landing/profile-landing.component';
import { ProfileExitComponent } from './profile-exit/profile-exit.component';
import { ProfileArticlesComponent } from './profile-articles/profile-articles.component';

@NgModule({
  declarations: [
    ProfileLandingComponent,
    ProfileExitComponent,
    ProfileArticlesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ContentModule,
    PipesModule,
    CommonComponentsModule
  ],
  exports: [
    ProfileLandingComponent
  ]
})
export class ProfileModule { }
