export const ARTICLE = {
  SETTINGS: 'SETTINGS',
  HEADER: 'HEADER',
  PARAGRAPH: 'PARAGRAPH',
  SLIDER: 'SLIDER',
  CITE: 'CITE',
  IMAGE: 'IMAGE'
};

export const LANG_ENG = 'en';
export const LANG_RU = 'ru';
export const LANG_KU = 'ku';
export const LANG_TUR = 'tur';
export const LANG_AR = 'ar';

const LTR = 'ltr';
const RTL = 'rtl';

const LANG_LTR_SETTINGS = {
  direction: LTR
};

const LANG_RTL_SETTINGS = {
  direction: RTL
};

export const DEFAULT_UI_LANG = LANG_ENG;
export const DEFAULT_UI_DIR = LTR;

export const LANG_SETTINGS = {
  [LANG_ENG]: LANG_LTR_SETTINGS,
  [LANG_RU]: LANG_LTR_SETTINGS,
  [LANG_KU]: LANG_LTR_SETTINGS,
  [LANG_AR]: LANG_RTL_SETTINGS,
  [LANG_TUR]: LANG_LTR_SETTINGS,
};

export const PUBLISHED_ENTITY_STATUS = 3;
