import { Injectable } from '@angular/core';
import { RestApiService } from '@besinor/services';
import { Observable } from 'rxjs';
import { IRaw, IResponse } from '@besinor/core';
import { HOST } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  constructor(private http: RestApiService) {}

  articleById(id: string): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/${id}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  articleByIdForPreview(id: string): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/preview/${id}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  listArticlesByPage(lang: string, page: number = 0, sort: string = 'new'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/all/${lang}/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  listStreamArticles(lang: string, streamId: string, page: number = 0, sort: string = 'new'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/streams/${streamId}/${lang}/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  listSimilarArticles(articleId: string, page: number = 0, sort: string = 'popular'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/similar/${articleId}/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  getTopStreams(limit: number = 5): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.http
      .get(`${HOST}/streams/top/${limit}`) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  };

  listUserArticles(userId: string, page: number = 0, sort: string = 'new'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/users/${userId}/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  listGroupById(groupId: string): Observable<IResponse<IRaw.IArticlesGroup[]>> {

    return this.http.get(`${HOST}/articles-groups/name/${groupId}`) as Observable<IResponse<IRaw.IArticlesGroup[]>>;
  }

  // Navigation
  getStreamData(streamId: string): Observable<IResponse<IRaw.INavigationStreamItem[]>> {

    return this.http.get(`${HOST}/streams/${streamId}`) as Observable<IResponse<IRaw.INavigationStreamItem[]>>;
  }

  getHubData(hubId: string): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.http.get(`${HOST}/hubs/${hubId}`) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  }

  listHubArticles(lang: string, hubId: string, page: number = 0, sort: string = 'new'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/articles/hubs/${hubId}/${lang}/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  getTopHubs(lang: string, items: number): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.http
      .get(`${HOST}/hubs/top/${lang}/${items}`) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  };

  getHubsByStreamId(streamId: string, lang: string): Observable<IResponse<IRaw.INavigationHubItem[]>> {

    return this.http.get(`${HOST}/hubs/list-with-articles/${lang}?streamId=${streamId}`) as Observable<IResponse<IRaw.INavigationHubItem[]>>;
  }

  // others
  getL10nItemById(fileId: string): Observable<IResponse<IRaw.ILocalization[]>> {

    return this.http.get(`${HOST}/localizations/${fileId}`) as Observable<IResponse<IRaw.ILocalization[]>>;
  }

  // Ptivate
  public getMyArticlesByPage(page: number, sort: string = 'new'): Observable<IResponse<IRaw.IArticle[]>> {

    return this.http.get(`${HOST}/my-articles/list/${page}?sort=${sort}`) as Observable<IResponse<IRaw.IArticle[]>>;
  }

  // comments
  public addCommentToArticle(articleId: string, data: string): Observable<IResponse<string>> {

    return this.http.put(`${HOST}/comments/addToArticle/${articleId}`, { data }) as Observable<IResponse<string>>;
  }

  public addCommentToComment(commentId: string, data: string): Observable<IResponse<string>> {

    return this.http.put(`${HOST}/comments/addToComment/${commentId}`, { data }) as Observable<IResponse<string>>;
  }

  public listCommentsByArticleId(articleId: string, page: number = 0): Observable<IResponse<IRaw.IComment[]>> {

    return this.http.get(`${HOST}/comments/byArticleId/${articleId}/${page}`) as Observable<IResponse<IRaw.IComment[]>>;
  }

  public setArticleInterractions(articleId: string, data: any): Observable<IResponse<any>> {

    return this.http.patch(`${HOST}/users-articles-ctrls/${articleId}`, data) as Observable<IResponse<any>>;
  }

  public getArticleInterractions(articleId: string): Observable<IResponse<IRaw.IUserArticleInterraction>> {

    return this.http.get(`${HOST}/users-articles-ctrls/${articleId}`) as Observable<IResponse<IRaw.IUserArticleInterraction>>;
  }

  public getArticleTotalVotes(articleId: string): Observable<IResponse<number>> {

    return this.http.get(`${HOST}/articles/votes/${articleId}`) as Observable<IResponse<number>>;
  }

  // reviews
  addArticleReview(articleId: string, review: any) : Observable<IResponse<string>> {

    return this.http.put(`${HOST}/articles-review/${articleId}`, review) as Observable<IResponse<string>>;
  }


}
