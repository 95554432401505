<div class="ql-editor article" [dir]="data.lang | langDir" *ngIf="data">
  <ng-container *ngFor="let part of (ASYNC_DATA(data.data) | async)">
    <ng-container [ngSwitch]="part.type">
      <besinor-article-header
        *ngSwitchCase="HEADER"
        [data]="part"
        [skipOverview]="true"
        [hubs]="data.hubs"
        [modified]="data.modified"
        [lang]="data.lang"
        [user]="data.user"
        [isMobile]="isMobile"
        [votes]="data.votes"
        [hidden]="noHeader"
      ></besinor-article-header>
      <besinor-article-paragraph
        *ngSwitchCase="PARAGRAPH"
        [data]="part"
      ></besinor-article-paragraph>
      <besinor-article-cite
        *ngSwitchCase="CITE"
        [data]="part"
      ></besinor-article-cite>
      <besinor-article-image
        *ngSwitchCase="IMAGE"
        [data]="part"
        [isMobile]="isMobile"
     ></besinor-article-image>
     <besinor-article-slider
        *ngSwitchCase="SLIDER"
        [data]="part"
        [isMobile]="isMobile"
    ></besinor-article-slider>
    </ng-container>
  </ng-container>
  <div class="share">
    <app-share></app-share>
  </div>
</div>
