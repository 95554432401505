import { Component, Input } from '@angular/core';
import { IMapped } from '@besinor/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: [
    '../../sharedStyles.scss',
    './side-bar.component.scss'
  ]
})
export class SideBarComponent {
  @Input() header: string;
  @Input() items: IMapped.IArticleShort[] = [];
}
